import React from 'react';

const ImageContent = ({ data }) => {
  return (
    <div>
      <img src={data.contentUrl} alt='img' />
    </div>
  );
};

export default ImageContent;
