import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import Modal from 'react-modal';
import { getError, formatDate } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import placeholder from 'assets/video.jpg';
import { IconKey } from 'shared/components/Icons';
import InputGroup from 'shared/components/InputGroup';

const ConfirmRequestModal = ({ data, type, fromUserProfile, onModalClose, onSuccess }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [fetch, setFetch] = useState(false);
  const isAccept = type === 'accept';
  const isDecline = type === 'decline';
  const isEdit = type === 'edit';

  const [form, setForm] = useState({
    requestId: data.id,
    userSubscriptionId: data.userSubscriptionId,
    uniqueKey: data.uniqueKey,
    medicalLicenseNumber: data.medicalLicenseNumber,
    npiNumber: data.npiNumber,
  });

  const handleModalSubmit = async () => {
    try {
      setFetch(true);
      const path = isAccept
        ? '/subscription/approve'
        : isDecline
        ? '/subscription/decline'
        : '/subscription/request/updatelicense';
      let body = !fromUserProfile ? { id: data.id } : { userSubscriptionId: data.id };
      if (isEdit) body = { ...form };
      await Api.post(path, body);
      if (onSuccess) onSuccess();
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetch(false);
      onModalClose();
    }
  };

  const onHandleForm = ({ target }, trim) => {
    const value = trim ? target.value.trim() : target.value;
    setForm({ ...form, [target.name]: value });
  };

  return (
    <Modal
      className='modal-default subscription-modal flex-column d-flex'
      isOpen={true}
      onRequestClose={onModalClose}
      contentLabel='Connection and device test'
    >
      <button className='button-times' onClick={onModalClose} />
      <img
        className='subscription-image'
        src={data.subscription.imageUrl || placeholder}
        alt={data.subscription.title}
      />
      <h5 className='my-3'>{data.subscription.title}</h5>
      {data.learner && (
        <div className='d-flex justify-content-between text-black-50 text-desc bold weight-400'>
          <span>User</span>
          <span>
            {data.learner.firstName} {data.learner.lastName}
          </span>
        </div>
      )}
      <div className='d-flex justify-content-between text-black-50 text-desc bold weight-400'>
        <span>Date</span>
        <span>{formatDate(data.createdAt)}</span>
      </div>
      <div className='d-flex justify-content-between text-black-50 text-desc bold weight-400'>
        <span>Code</span>
        <span>{data.uniqueKey || '-'}</span>
      </div>
      {isEdit && (
        <>
          <div className='password-input d-flex mt-3'>
            <div className='pr-3 pl-2 pass-icon mb-2'>
              <IconKey />
            </div>
            <InputGroup
              className='pl-3'
              type='text'
              name='uniqueKey'
              value={form.uniqueKey}
              placeholder='Registration Code'
              disabled={fetch}
              onChange={e => {
                onHandleForm(e, true);
              }}
            />
          </div>
          <div className='password-input d-flex mt-2'>
            <div className='pr-3 pl-2 pass-icon mb-2'>
              <IconKey />
            </div>
            <InputGroup
              className='pl-3'
              type='text'
              name='medicalLicenseNumber'
              value={form.medicalLicenseNumber}
              placeholder='Medical License Number'
              disabled={fetch}
              onChange={onHandleForm}
            />
          </div>
          <div className='password-input d-flex mt-2'>
            <div className='pr-3 pl-2 pass-icon mb-2'>
              <IconKey />
            </div>
            <InputGroup
              className='pl-3'
              type='text'
              name='npiNumber'
              value={form.npiNumber}
              placeholder='NPI number'
              disabled={fetch}
              onChange={onHandleForm}
            />
          </div>
        </>
      )}
      <div className='d-flex justify-content-between mt-3'>
        {!isEdit && (
          <button className='btn-white btn-block btn mr-3' disabled={fetch} onClick={onModalClose}>
            Cancel
          </button>
        )}
        <button
          className={`btn-white btn-block btn mt-0 ${isEdit ? '' : 'ml-3'}`}
          disabled={fetch}
          onClick={handleModalSubmit}
        >
          {isAccept ? 'Accept' : isDecline ? 'Decline' : 'Update and Accept'}
        </button>
      </div>
    </Modal>
  );
};

export default ConfirmRequestModal;
