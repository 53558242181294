import React from 'react';
import { bindDuration } from 'utils/appHelpers';
import { IconVideos } from 'shared/components/Icons';

const CourseLessonHeader = ({ data: { title, duration, episodes, imageUrl } }) => {
  return (
    <>
      <div className='d-flex'>
        <div className='col-3 p-0'>
          <img src={imageUrl} alt='Part Description' />
        </div>
        <div className='col-9 pr-0'>
          <h6 className='color-blue'>{title}</h6>
          <div className='d-flex'>
            <span className='mr-3 d-flex align-items-center'>
              {episodes.length} <IconVideos className='ml-1' />
            </span>
            <span>{bindDuration(duration)}</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default CourseLessonHeader;
