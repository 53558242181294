/* eslint-disable eqeqeq */
import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { Link } from 'react-router-dom';
import { IconEye } from 'shared/components/Icons';
import { getImageUrl, getError } from 'utils/appHelpers';
import { userStatuses } from 'app/Main/configs';
import { Api } from 'utils/connectors';
import ClickArea from 'shared/components/ClickArea';
import Loading from 'shared/components/Loading';

const headers = [
  {
    name: 'Name, Surname',
    className: 'col-3',
    key: 'fullName',
  },
  {
    name: 'Email',
    className: 'col-3',
    key: 'email',
  },
  {
    name: 'Role',
    className: 'col-2',
    key: 'roles',
  },
  {
    name: 'Status',
    className: 'col border-right-0',
    key: '',
  },
  {
    name: '',
    className: '',
    key: '',
  },
];

const UsersLists = ({ users, order, changeOrder, status, fetch }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [userInviteFetch, setUserInviteFetch] = useState(false);

  if (fetch) return <Loading className='mt-5' />;

  if (!users || !users.length)
    return <div className='text-black-50 text-center mt-4'>No Results Found</div>;

  const handleOrderChange = item => {
    if (!item.key) return;
    changeOrder({
      key: item.key,
      order: !order.order,
    });
  };

  const userResetInviteEmail = async id => {
    try {
      setUserInviteFetch(true);
      await Api.post(`/admin/resendinvitation/${id}`, {});
      enqueueSnackbar('Invitation sent successfully', { variant: 'success' });
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setUserInviteFetch(false);
    }
  };

  const invitedStatus = Number(status) === 4;

  return (
    <div className='users-lists'>
      <ul className='p-0'>
        <li className='d-flex list-header align-items-center'>
          {headers.map((item, index) => (
            <ClickArea
              key={item.name + index}
              className={`d-flex text-body justify-content-between align-items-center ${
                item.className
              }`}
              onClick={handleOrderChange.bind(null, item)}
            >
              {item.name}
              {item.key && order.orderKey === item.key && (
                <button
                  className={`btn ml-2 p-0 dropdown-toggle ${order.order ? '' : 'rotate-180'}`}
                />
              )}
            </ClickArea>
          ))}
        </li>
        {users &&
          users.map(item => {
            const role = item.roles?.length ? item.roles[0] : null;
            return (
              <li key={item.id} className='d-flex align-items-center list-item'>
                <div className='d-flex col-3 align-items-center'>
                  <img
                    src={getImageUrl(item.imageUrl)}
                    alt={item.firstname}
                    className='user-image mr-2'
                  />
                  <div>
                    <b>
                      {item.firstName} {item.lastName}
                    </b>
                  </div>
                </div>
                <div className='col-3'>{item.email}</div>
                <div className='col-2'>{role?.title || '-'}</div>
                <div className='col'>
                  {invitedStatus && 'Invitation Sent'}
                  {!invitedStatus && (userStatuses[item.userStatus] || '-')}
                </div>
                <div className='p-2 d-flex justify-content-end align-items-center action-buttons'>
                  {invitedStatus && (
                    <button
                      className='more-btn small mr-2'
                      disabled={userInviteFetch}
                      onClick={() => userResetInviteEmail(item.id)}
                    >
                      Resend
                    </button>
                  )}
                  <Link
                    className='btn action-item'
                    to={`/admin/${item.id}/${
                      invitedStatus || item.userStatus == 4 ? 'invited' : ''
                    }`}
                  >
                    <IconEye />
                  </Link>
                </div>
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default UsersLists;
