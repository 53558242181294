import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { formatDate, getError } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import { Link } from 'react-router-dom';
import Loading from 'shared/components/Loading';
import DicomList from './components/DicomList';
import ShareCaseBlock from './components/ShareCaseBlock';

const WarehouseCaseView = ({ match, history }) => {
  const { id } = match.params;
  const { enqueueSnackbar } = useSnackbar();
  const [caseData, setCaseData] = useState();

  const onGetCaseData = async e => {
    try {
      const { data } = await Api.get(`/cases/case/${id}`);
      setCaseData(data.data);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
      history.push('/cases/all');
    }
  };

  useEffect(() => {
    onGetCaseData();
    //eslint-disable-next-line
  }, [id]);

  if (!caseData) return <Loading className='mt-5' />;

  const usedNames = caseData.usedCourses && caseData.usedCourses.map(i => i.course.name);

  return (
    <div>
      <div className='users-header d-flex justify-content-between align-items-center'>
        <div className='d-flex breadcrumbs'>
          <Link to='/warehouse/cases/all'>All Cases</Link>
          <span className='mx-2'>/</span>
          <span>{caseData.scanId}</span>
        </div>
      </div>
      <div className='d-flex mb-3 has-header'>
        <div className='col-8'>
          <ul className='list-default list-settings'>
            <li className='d-flex align-items-center p-3'>
              <div className='col-4 pl-0 weight-700'>caseData:</div>
              <div className='col weight-200'>{caseData.scanId}</div>
            </li>
            <li className='d-flex align-items-center p-3'>
              <div className='col-4 pl-0 weight-700'>Dicom Types:</div>
              <div className='col weight-200'>{caseData.dicomTypes}</div>
            </li>
            <li className='d-flex align-items-center p-3'>
              <div className='col-4 pl-0 weight-700'>Is Used In:</div>
              <div className='col weight-200'>{usedNames ? usedNames.join(', ') : '-'}</div>
            </li>
            <li className='d-flex align-items-center p-3'>
              <div className='col-4 pl-0 weight-700'>Patient Age:</div>
              <div className='col weight-200'>{caseData.patientAge}</div>
            </li>
            <li className='d-flex align-items-center p-3'>
              <div className='col-4 pl-0 weight-700'>Scan Date:</div>
              <div className='col weight-200'>{formatDate(caseData.scanDate)}</div>
            </li>
          </ul>
        </div>
        <div className='col-4'>
          <ShareCaseBlock onSuccess={onGetCaseData} caseData={caseData} />
        </div>
      </div>
      <DicomList dicoms={caseData.dicoms} onGetCaseData={onGetCaseData} />
    </div>
  );
};

export default WarehouseCaseView;
