import React from 'react';
import { IconPlayFill, IconVideos } from 'shared/components/Icons';
import { bindDuration } from 'utils/appHelpers';
import { DEFAULT_IMG_URLS } from 'configs';

const notSetTitleText = 'Lesson name is not set up yet';
const notSetDescriptionText = 'Lesson description text is not provided';

const LessonsListSection = ({ lessons, activeLesson, setActiveLesson, setIsClickedOnEval }) => {
  const lessonClickHandle = (lesson, e) => {
    setIsClickedOnEval(lesson.contentType === 5);
    setActiveLesson(lesson);
  };

  return (
    <div className='courses-list'>
      {lessons &&
        lessons.map((lesson, i) => {
          const Icon = IconPlayFill;
          const active = (lesson.id || lesson.title) === (activeLesson.id || activeLesson.title);
          const isFeedback = lesson.contentType === 5;
          return (
            <div
              key={i}
              onClick={lessonClickHandle.bind(null, lesson)}
              role='button'
              tabIndex='-1'
              className={`list-item d-flex pointer ${
                active ? 'active' : ''
              } justify-content-between`}
            >
              <div className='d-flex align-items-center'>
                <div aria-label='lessonPlay'>
                  <Icon ariaLabel='lessonPlay' />
                </div>
                <div className='lesson-image-area mx-3'>
                  {<img src={lesson.imageUrl || DEFAULT_IMG_URLS.lesson} alt={lesson.title} />}
                </div>
                <div className='d-flex flex-column'>
                  <p className='name'>{lesson.title || notSetTitleText}</p>
                  <p className='description'>
                    {lesson.info || lesson.description || notSetDescriptionText}
                  </p>
                </div>
              </div>
              {!isFeedback && (
                <div className='time-part d-flex flex-column justify-content-around align-items-end'>
                  <span>
                    {lesson?.episodes?.length || 0} <IconVideos />
                  </span>
                  <p>{bindDuration(lesson.duration || 0)}</p>
                </div>
              )}
            </div>
          );
        })}
    </div>
  );
};

export default LessonsListSection;
