import React from 'react';

const Author = ({
  data: { imageUrl = '', name = '', surname = '', profession = '', info = '' },
  className = 'p-4',
}) => (
  <div className={className}>
    <div className='author'>
      <div className='author-img'>
        <img src={imageUrl} alt={`${name}`} />
      </div>
      <p className='mt-2 mb-0 weight-900 text-clip'>
        {name} {surname}
      </p>
      <p className='small text-secondary mb-0'>{profession}</p>
    </div>
  </div>
);

export default Author;
