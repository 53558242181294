import twitter from 'assets/social-icons/twitter.svg';
import facebook from 'assets/social-icons/facebook.svg';
import linkedin from 'assets/social-icons/linkedin.svg';
import youtube from 'assets/social-icons/youtube.svg';
import instagram from 'assets/social-icons/instagram.svg';

export const PLATFORM = {
  learner: 1,
  admin: 2,
  hcb: 3,
};

export const LESSON_STATUSES = {
  inProgress: 1,
  notStarted: 2,
  completed: 3,
};

export const SOCIALS = [
  {
    img: twitter,
    url: 'https://twitter.com/Hologic',
  },
  {
    img: linkedin,
    url: 'https://www.linkedin.com/company/hologic',
  },
  {
    img: facebook,
    url: 'https://www.facebook.com/Hologic',
  },
  {
    img: youtube,
    url: 'https://www.youtube.com/@HologicInc',
  },
  {
    img: instagram,
    url: 'https://www.instagram.com/hologic/',
  },
];

export const COURSE_TYPES = {
  1: 'video',
  2: 'pdf',
  3: 'cases',
  4: 'image',
  5: 'feedback',
  6: 'quiz',
};

export const SUBSCRIPTION_PLAN_TYPES = {
  1: 'Educational',
  4: 'Event Container',
  5: 'Marketing',
  6: 'Product',
  Educational: 1,
  'Event Container': 4,
  Marketing: 5,
  Product: 6,
};

export const REGISTER_TOKEN_RESPONSE_CODES = {
  invalidInvitationToken: 106,
  expiredInvitationToken: 107,
  expiredConfirmationCode: 108,
};
