import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import LessonCasesBlock from './LessonCasesBlock';
import { getError } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import EditingSelect from 'shared/components/editingForms/EditingSelect';
import { getDicomsSelects, setHeatmapsIds } from '../utils';

const hasHeatmapTypes = [
  { name: 'Heatmap Available', value: '1' },
  { name: 'No Heatmap', value: '0' },
];

const getInitialConfigs = (caseId, dicoms, initData) => {
  const confs = initData[caseId];
  if (!confs) return null;
  const dicom = dicoms.find(i => i.id === confs.dicomId);
  if (!dicom) return null;
  let sliceIndex;
  if (dicom.dicomImages) {
    sliceIndex = dicom.dicomImages.findIndex(i => i.id === confs.imageId);
  }
  return {
    ...confs,
    hasHeatmap: 1,
    imageType: dicom.imageType,
    imageLaterality: dicom.imageLaterality,
    viewPosition: dicom.viewPosition,
    slice: sliceIndex + 1,
  };
};

const getInitialData = () => {
  return { hasHeatmap: 1 };
};

const LessonCasesHeatmaps = ({ data, setData, isInUse, isEditing, isRequired }) => {
  const { enqueueSnackbar } = useSnackbar();
  const initCaseId = data.activeCases[0] && data.activeCases[0].caseId;
  const [heatmaps, setHeatmaps] = useState(data.caseHeatmaps || {});
  const [currentCase, setCurrentCase] = useState(initCaseId);
  const [dicoms, setDicoms] = useState({});

  const getCaseDicoms = async id => {
    try {
      const patientId = data.activeCases.find(i => i.caseId === id).patientId;
      const res = await Api.get(`/cases/case/${patientId}?images=true`);
      setDicoms({ ...dicoms, [id]: res.data.data.dicoms });
      if (isEditing) {
        const initConfs = getInitialConfigs(id, res.data.data.dicoms, data.caseHeatmaps);
        if (initConfs) setHeatmaps({ ...heatmaps, [id]: initConfs });
      }
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const handleChange = ({ target }) => {
    let { name, value } = target;
    const tempHeat = { ...heatmaps };
    if (name === 'hasHeatmap') value = Number(value);
    tempHeat[currentCase] = {
      ...(tempHeat[currentCase] || getInitialData(currentCase)),
      [name]: value,
    };
    setHeatmaps(tempHeat);
  };

  const checkCaseCompleted = ({ caseId }) => {
    const caseI = data && data.caseHeatmaps && data.caseHeatmaps[caseId];
    if (caseI && !caseI.hasHeatmap) return true;
    if (caseI && Object.keys(caseI).length === 2 && caseI.imageId) return true;
    const form = heatmaps[caseId];
    const datas = getDicomsSelects(dicoms[caseId], form);
    return datas && datas.image;
  };

  useEffect(() => {
    if (currentCase && !dicoms[currentCase]) getCaseDicoms(currentCase);
    //eslint-disable-next-line
  }, [currentCase]);

  useEffect(() => {
    const caseHeatmaps = setHeatmapsIds(dicoms, heatmaps);
    setData({ ...data, caseHeatmaps: { ...data.caseHeatmaps, ...caseHeatmaps } });
    //eslint-disable-next-line
  }, [heatmaps]);

  const form = heatmaps[currentCase] || getInitialData(currentCase);
  const datas = getDicomsSelects(dicoms[currentCase], form);

  return (
    <div className='px-2 lesson-heatmaps'>
      <h6 className='color-blue mb-3'>Imagery Calcification Selection</h6>
      <LessonCasesBlock
        cases={data.activeCases}
        activeCase={currentCase}
        onClickCase={setCurrentCase}
        isCompletedCase={checkCaseCompleted}
      />
      <div className='col-6 p-0 user-creation-form'>
        <ul className='p-0 mb-0'>
          <li className='d-flex align-items-center'>
            <div className='col-5 pl-0'>
              <label className='m-0'>Case Heatmap*:</label>
            </div>
            <EditingSelect
              name='hasHeatmap'
              onChange={handleChange}
              value={form && form.hasHeatmap}
              items={hasHeatmapTypes}
              useProperty='value'
              displayProperty='name'
              hideDefault
              required={isRequired}
              editing={!isInUse}
            />
          </li>
        </ul>
      </div>
      {form && !!form.hasHeatmap && (
        <div className='d-flex my-4 justify-content-between'>
          <div className='col-3 p-0 user-creation-form'>
            <ul className='p-0 mb-0'>
              <li className='d-flex align-items-center'>
                <div className='col-3 pl-0'>
                  <label className='m-0'>Type*:</label>
                </div>
                <EditingSelect
                  name='imageType'
                  onChange={handleChange}
                  value={form.imageType || ''}
                  items={datas.types || []}
                  useProperty='value'
                  displayProperty='name'
                  required={isRequired}
                  editing={!isInUse}
                />
              </li>
              <li className='d-flex align-items-center'>
                <div className='col-3 pl-0'>
                  <label className='m-0'>Side*:</label>
                </div>
                <EditingSelect
                  name='imageLaterality'
                  onChange={handleChange}
                  value={form.imageLaterality || ''}
                  items={datas.sides || []}
                  useProperty='value'
                  displayProperty='name'
                  required={isRequired}
                  editing={!isInUse}
                />
              </li>
              <li className='d-flex align-items-center'>
                <div className='col-3 pl-0'>
                  <label className='m-0'>Angle*:</label>
                </div>
                <EditingSelect
                  name='viewPosition'
                  onChange={handleChange}
                  value={form.viewPosition || ''}
                  items={datas.angles || []}
                  useProperty='value'
                  displayProperty='name'
                  required={isRequired}
                  editing={!isInUse}
                />
              </li>
              <li className='d-flex align-items-center'>
                <div className='col-3 pl-0'>
                  <label className='m-0'>Slice*:</label>
                </div>
                <EditingSelect
                  name='slice'
                  onChange={handleChange}
                  value={form.slice || ''}
                  items={datas.slices || []}
                  useProperty='value'
                  displayProperty='name'
                  required={isRequired}
                  editing={!isInUse}
                />
              </li>
            </ul>
          </div>
          <div className='col-8 image-area text-center'>
            {datas.image && <img height='100%' src={datas.image.url} alt='iig' />}
          </div>
        </div>
      )}
    </div>
  );
};

export default LessonCasesHeatmaps;
