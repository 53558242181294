/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import Accordion from 'app/Main/components/Accordion';
import moment from 'moment';
import React from 'react';
import { IconCalendar } from 'shared/components/Icons';
import { getImageUrl } from 'utils/appHelpers';
import EventCourseHeader from './EventCourseHeader';
import EventCourseBody from './EventCourseBody';
import { DEFAULT_IMG_URLS } from 'configs';

const constructPrograms = programs => {
  const dates = {};
  programs.forEach(item => {
    const date = moment(item.startTime).format('MM/DD/YYYY');
    dates[date] = dates[date] || [];
    dates[date].push(item);
  });
  return dates;
};

const EventSchedule = ({ event }) => {
  const data = constructPrograms(event.programs);
  const dates = Object.keys(data);

  const sortedDates = dates
    .map(i => new Date(data[i][0].startTime))
    .sort((a, b) => a - b)
    .map(i => moment(i).format('MM/DD/YYYY'));

  return (
    <div className='event-cme' id='cmeSection'>
      <h5 className='py-2 mb-1 color-blue'>Schedule</h5>
      <div className='event-schedule-list'>
        {sortedDates.map(item => {
          return (
            <div key={item} className='date-block'>
              <div className='date-title d-flex align-items-baseline'>
                <span>{moment(item).format('DD')}</span>
                {moment(item).format('MMMM')}
              </div>
              <div className='programs-list'>
                {data[item] &&
                  data[item].map(program => {
                    const author = program.authorProfile;
                    const adaptedCourses = data[item].map(item => ({
                      ...item.course,
                      imageUrl: item?.course?.imageUrl || DEFAULT_IMG_URLS.course,
                      courseLessons: item.course.courseLessons.map(({ lesson }) => lesson),
                    }));
                    return (
                      <div className='program-block' key={program.id}>
                        <div className='time d-flex align-items-center'>
                          <IconCalendar className='mr-3' />
                          {moment(program.startTime).format('hh:mm a')} - {''}
                          {moment(program.endTime).format('hh:mm a')}
                        </div>
                        <div className='desc'>{program.description}</div>

                        <div className='my-4'>
                          <div>
                            <Accordion
                              data={adaptedCourses}
                              HeaderComponent={EventCourseHeader}
                              BodyComponent={EventCourseBody}
                              childrenKey='courseLessons'
                              className='parts-accordion bordered_main'
                              childrenClassName='mb-2'
                              contentClassName='pt-0'
                              noDefaultActiveIndex={true}
                            />
                          </div>
                        </div>

                        {author && (
                          <div className='d-flex'>
                            <div className='author d-flex align-items-center'>
                              <div className='author-img col-8 mr-4'>
                                <img
                                  src={getImageUrl(author.imageUrl)}
                                  className='bg-light'
                                  alt={`${author.name}`}
                                />
                              </div>
                              <div>
                                <p className='mt-2 mb-0 weight-900 text-clip'>
                                  {author.name} {author.surname}
                                </p>
                                <p className='small text-left text-secondary mb-0'>
                                  {author.profession}
                                </p>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default EventSchedule;
