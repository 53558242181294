import React from 'react';

export const QuizViewImageColumn = ({ column }) => {
  const { name, items } = column;
  return (
    <div className='quiz_view_imgs_column_container'>
      <div className='head_block'>{name}</div>
      <div className='body_block'>
        <div className='scrollable_block'>
          {items?.length &&
            items.map((imgItem, idx) => (
              <div key={idx} className='img_item'>
                <img alt='category_img' src={imgItem.imageUrl} />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};
