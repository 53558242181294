/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Api } from 'utils/connectors';
import { getError, reorder } from 'utils/appHelpers';
import EditingInput from 'shared/components/editingForms/EditingInput';
import Loading from 'shared/components/Loading';
import DragList from 'shared/components/DragList';
import moveIcon from 'assets/move.svg';
import { ReactComponent as RemoveIcon } from 'assets/bin.svg';
import { IconClose } from 'shared/components/Icons';
import Select from 'shared/components/Select';

const dataLaterality = [{ name: 'L', value: 'L' }, { name: 'R', value: 'R' }];
const dataPositions = [{ name: 'CC', value: 'CC' }, { name: 'MLO', value: 'MLO' }];
const dataImageType = [
  { name: '2D', value: 'i2d' },
  { name: '3DQ', value: '3dq' },
  { name: 'Tomo', value: 'tomo' },
  { name: 'Sub', value: 'sub' },
];

const getEmptyFrame = () => {
  return { imageLaterality: '', viewPosition: '', imageType: '', year: '' };
};

const getEmptyStep = (index = 1) => {
  return { step: index, frames: [{ ...getEmptyFrame() }, { ...getEmptyFrame() }] };
};

const ProtocolsNew = ({ history, match, location }) => {
  const { id } = match.params;
  const sampleProtocolId = location?.state?.sampleId;
  const isEdit = !!match.params.id && !sampleProtocolId;
  const { enqueueSnackbar } = useSnackbar();
  const [fetching, setFetching] = useState(false);
  const [data, setData] = useState();
  const [activeStep, setActiveStep] = useState(1);
  const [form, setForm] = useState({
    name: '',
    steps: [{ ...getEmptyStep(1) }],
  });

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setForm({ ...form, [name]: value });
  };

  const handleFrameChange = (index, { target }) => {
    const { name, value } = target;
    const tempData = { ...form };
    const step = tempData.steps.find(i => i.step === activeStep);
    step.frames[index][name] = value;
    setForm(tempData);
  };

  const getProtocol = async () => {
    try {
      const { data } = await Api.get(`/hanging-protocol/${id || sampleProtocolId}`);
      const body = { ...data.data, originId: id };
      setForm(body);
      setData(data.data);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const onFormSubmit = async e => {
    e.preventDefault();
    try {
      setFetching(true);
      const body = { ...form, steps: [] };
      form.steps.forEach((step, i) => {
        body.steps.push({ step: i + 1, frames: step.frames });
      });
      await Api.post(`/wizard/hanging-protocol/${isEdit ? 'update' : 'init'}`, body);
      enqueueSnackbar(`Successfully ${isEdit ? 'updated' : 'created'}`, { variant: 'success' });
      history.push('/protocols/all');
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
      setFetching(false);
    }
  };

  const onDragEnd = e => {
    if (!e.destination) return;
    const tempData = { ...form };
    tempData.steps = reorder(tempData.steps, e.source.index, e.destination.index);
    setForm(tempData);
  };

  const onRemoveStep = (index, e) => {
    e.preventDefault();
    e.stopPropagation();
    const tempData = { ...form };
    const currentStep = tempData.steps[index];
    tempData.steps.splice(index, 1);
    if (activeStep === currentStep.step) {
      setActiveStep(tempData.steps[0].step);
    }
    tempData.steps = tempData.steps.map((item, index) => {
      item.step = index + 1;
      return item;
    });
    setForm(tempData);
  };

  const onAddStep = () => {
    const tempData = { ...form };
    form.steps.push(getEmptyStep(form.steps.length + 1));
    setForm(tempData);
    setTimeout(setActiveStep, 10, form.steps.length);
  };

  const addNewFrames = () => {
    const tempData = { ...form };
    const step = tempData.steps.find(i => i.step === activeStep);
    step.frames.push({ ...getEmptyFrame() }, { ...getEmptyFrame() });
    setForm(tempData);
  };

  const removeFrame = removeFromStart => {
    const tempData = { ...form };
    const step = tempData.steps.find(i => i.step === activeStep);
    const [f1, f2, f3, f4] = step.frames;
    step.frames = removeFromStart ? [f3, f4] : [f1, f2];
    setForm(tempData);
  };

  useEffect(() => {
    if (isEdit || sampleProtocolId) getProtocol();
  }, []);

  if (isEdit && !data) return <Loading />;

  const btnDisabled = !form.name;
  const activeStepForms = form.steps.find(i => i.step === activeStep)?.frames;
  const selectBoxClassName = activeStepForms?.length === 4 ? 'col-12' : 'w-48';

  return (
    <div>
      <div className='users-header d-flex justify-content-between align-items-center'>
        <div className='d-flex breadcrumbs'>
          <Link to='/protocols/all'>All Hanging Protocols</Link>
          <span className='mx-2'>/</span>
          <span>{isEdit ? 'Update' : 'Add New'} Hanging Protocol</span>
        </div>
      </div>
      <form className='py-3 has-header' onSubmit={onFormSubmit}>
        <div className='col-12 user-creation-form'>
          <ul className='p-0 mb-0 col-9'>
            <li className='d-flex align-items-center'>
              <div className='col-3 pl-0'>
                <label className='m-0'>Name:</label>
              </div>
              <EditingInput
                type='text'
                name='name'
                value={form.name}
                onChange={handleChange}
                className='p-0'
                placeholder='Enter Name'
                required
                editing={true}
              />
            </li>
          </ul>
          <div className='protocol-steps d-flex align-items-center'>
            <DragList
              onDragEnd={onDragEnd}
              rowClassName='mb-0'
              items={form.steps}
              direction='horizontal'
            >
              {(item, index) => {
                const isActive = activeStep === item.step;
                return (
                  <div
                    className={`protocol-step-box pointer ${isActive ? 'active' : ''}`}
                    onClick={setActiveStep.bind(null, item.step)}
                  >
                    {form.steps.length > 1 && (
                      <button
                        type='button'
                        className='btn p-0 remove-btn'
                        onClick={onRemoveStep.bind(null, index)}
                      >
                        <IconClose />
                      </button>
                    )}
                    <div className='move-icon'>
                      <img src={moveIcon} alt='icon' />
                    </div>
                    {index + 1}
                  </div>
                );
              }}
            </DragList>
            <div className='protocol-step-box add-new-box pointer' onClick={onAddStep}>
              +
            </div>
          </div>
          {activeStepForms?.length === 4 && (
            <div className='remove-frames row d-flex'>
              <div className='col d-flex align-items-center' onClick={removeFrame.bind(null, true)}>
                <RemoveIcon className='mr-2' />
                Remove 1 & 2 Frames
              </div>
              <div
                className='col d-flex align-items-center'
                onClick={removeFrame.bind(null, false)}
              >
                <RemoveIcon className='mr-2' />
                Remove 3 & 4 Frames
              </div>
            </div>
          )}
          <div className='protocol-frames mt-3 row d-flex'>
            {activeStepForms?.map((item, i) => {
              return (
                <div className='col' key={i}>
                  <p>Frame {i + 1}</p>
                  <div className='example-image'>
                    <img alt='Example' />
                  </div>
                  <div>
                    <ul className='p-0 mb-0 col-12 d-flex flex-wrap justify-content-between'>
                      <li className={`d-flex align-items-center pl-3 ${selectBoxClassName}`}>
                        <div className='col-6 p-0'>
                          <label className='m-0'>Laterality*:</label>
                        </div>
                        <Select
                          name='imageLaterality'
                          onChange={handleFrameChange.bind(null, i)}
                          value={item.imageLaterality || ''}
                          items={dataLaterality}
                          useProperty='value'
                          displayProperty='name'
                          required={true}
                          editing={true}
                        />
                      </li>
                      <li className={`d-flex align-items-center pl-3 ${selectBoxClassName}`}>
                        <div className='col-6 p-0'>
                          <label className='m-0'>View Position*:</label>
                        </div>
                        <Select
                          name='viewPosition'
                          onChange={handleFrameChange.bind(null, i)}
                          value={item.viewPosition || ''}
                          items={dataPositions}
                          useProperty='value'
                          displayProperty='name'
                          required={true}
                          editing={true}
                        />
                      </li>
                      <li className={`d-flex align-items-center pl-3 ${selectBoxClassName}`}>
                        <div className='col-6 p-0'>
                          <label className='m-0'>Type*:</label>
                        </div>
                        <Select
                          name='imageType'
                          onChange={handleFrameChange.bind(null, i)}
                          value={item.imageType || ''}
                          items={dataImageType}
                          useProperty='value'
                          displayProperty='name'
                          required={true}
                          editing={true}
                        />
                      </li>
                      <li className={`d-flex align-items-center pl-3 ${selectBoxClassName}`}>
                        <div className='col-6 p-0'>
                          <label className='m-0'>Scan Year*:</label>
                        </div>
                        <EditingInput
                          type='number'
                          name='year'
                          min='1900'
                          max='2022'
                          step='1'
                          value={item.year}
                          onChange={handleFrameChange.bind(null, i)}
                          className='p-0 no-min-w'
                          placeholder='Year'
                          editing={true}
                        />
                      </li>
                    </ul>
                  </div>
                </div>
              );
            })}
            {activeStepForms?.length < 4 && (
              <button
                type='button'
                onClick={addNewFrames}
                className='btn btn-outline-blue add-new-frames'
              >
                Add 2 Frames +
              </button>
            )}
          </div>
          <div className='text-right mt-4'>
            <button type='submit' disabled={btnDisabled || fetching} className='btn btn-blue px-4'>
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ProtocolsNew;
