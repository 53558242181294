/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/no-danger-with-children */
import React, { useState, useEffect } from 'react';
import { Api } from 'utils/connectors';
import Loading from 'shared/components/Loading';
import EventViewHeader from './components/EventViewHeader';
import AuthorsList from './components/AuthorsList';
import PublishPreview from './components/PublishPreview';
import { useSnackbar } from 'notistack';
import { getError } from 'utils/appHelpers';
import moment from 'moment';
import Slider from 'react-slick';
import EventCME from './components/EventCME';
import EventSchedule from './components/EventSchedule';

const sliderSettings = {
  dots: true,
  speed: 500,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  autoplay: true,
};

const EventView = ({ match }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState();

  const getEventData = async () => {
    try {
      const { data } = await Api.get(`/events/event/${match.params.id}`);
      setData(data.data);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  useEffect(() => {
    getEventData();
  }, [match.params.id]);

  if (!data) return <Loading />;

  return (
    <div>
      <EventViewHeader title={data.name} />
      <div className='py-3 d-flex has-header'>
        <div className='col-9'>
          <div className='px-3'>
            <h4 className='py-2 mb-1 color-blue'>{data.name}</h4>
            <p>
              <span className='d-block mb-1 fz-16'>
                {moment(data.startDate).format('MMMM DD')} -{' '}
                {moment(data.endDate).format('MMMM DD')}
              </span>
              <span className='d-block white-space-pre fz-12'>{data.venue}</span>
            </p>
            {data.banners && data.banners.length && (
              <div className='mb-3'>
                <Slider {...sliderSettings}>
                  {data.banners.map((item, i) => {
                    return <img src={item.bannerUrl} key={i} />;
                  })}
                </Slider>
              </div>
            )}
            <div className='mt-5' dangerouslySetInnerHTML={{ __html: data.about }} />
            <h5 className='py-2 mb-1 color-blue'>Faculty</h5>
            {data.speakers && data.speakers.length > 0 && <AuthorsList data={data.speakers} />}
            <EventCME event={data} />
            <EventSchedule event={data} />
          </div>
        </div>
        <div className='col-3 py-3'>
          <PublishPreview data={data} getData={getEventData} />
        </div>
      </div>
    </div>
  );
};

export default EventView;
