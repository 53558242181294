/* eslint-disable consistent-return */
/* eslint-disable no-console */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import * as cornerstone from 'cornerstone-core';
import cornerstoneMath from 'cornerstone-math';
import cornerstoneTools from 'cornerstone-tools/dist/cornerstoneTools';
import * as cornerstoneWebImageLoader from 'cornerstone-web-image-loader';
import Hammer from 'hammerjs';
import Loading from 'shared/components/Loading';
import useInitialEffect from 'shared/hooks/useInitialEffect';
import { getToolByType, tools } from '../utils/configs';
import {
  createGenuieEventData,
  getCurrentImageMarksForPreOrPostSlice,
  getDicomImagesObj,
  maximazeElement,
  zoomImageMouseClick,
} from '../utils';
import { setCanBeActive } from '../actions';
import ArrowTool from '../plugins/ArrowTool';
import SelectTool from '../plugins/SelectTool';
import { getToolState } from '../plugins/stateManagement/toolState';
import GenuineTool from '../plugins/GenuineTool';
import GenuineTransparentTool from '../plugins/GenuineTransparentTool';
import StackControl from './StackControl';
import GenuineMode from './GenuineMode';
import { hasAccess } from 'utils/permissionHelper';
import AssociationsBlock from './AssociationsBlock';
import { useSnackbar } from 'notistack';
import { getError } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import { SHAPE_ICONS } from '../constants';
import { usePrevious } from 'shared/hooks/usePrevious';
import PrePostSlices from './PrePostSlices';

cornerstoneWebImageLoader.external.cornerstone = cornerstone;
cornerstoneTools.external.cornerstone = cornerstone;
cornerstoneTools.external.Hammer = Hammer;
cornerstoneTools.external.cornerstoneMath = cornerstoneMath;

cornerstoneTools.init({ mouseEnabled: true, showSVGCursors: false, autoResizeViewports: true });
cornerstoneTools.toolStyle.setToolWidth('4');

const SvgCursor = ({ cursorPos, shape, showSVG }) => {
  const style = {
    position: 'absolute',
    left: `${cursorPos.x + 12}px`,
    top: `${cursorPos.y + 14}px`,
    display: showSVG ? 'block' : 'none',
    pointerEvents: 'none',
  };

  return <img src={SHAPE_ICONS[shape]} style={style} alt='Cursor SVG' />;
};

export const CanvasView = ({
  fullScreen,
  activeTool,
  setActiveTool,
  dicomData,
  onUpdateGenuine,
  onUpdateSlices,
  onRemoveGaid,
  onGetDicom,
  outerUsedDesignators,
}) => {
  const isGenuine =
    (activeTool && activeTool.type === 'genuine') || activeTool.type === 'RemoveGenius';
  const isRemoveGenius = activeTool?.type === 'RemoveGenius';
  const dispatch = useDispatch();
  const enqueueSnackbar = useSnackbar();
  const [imageFetch, setImageFetch] = useState(false);
  const [viewportState, setViewportState] = useState();
  const [activeImage, setActiveImage] = useState();
  const [play, setPlay] = useState(false);
  const [cursorPos, setCursorPos] = useState({ x: 0, y: 0 });
  const [showSVG, setShowSVG] = useState(false);
  const [selectedPoint, setSelectedPoint] = useState({});
  const [gaidAssociations, setGaidAssociations] = useState([]);
  const [gaidDetails, setGaidDetails] = useState(null);
  const [usedDesignators, setUsedDesignators] = useState(outerUsedDesignators || []);

  const previousActiveTool = usePrevious(activeTool);

  const element = useRef(null);
  const curentLoopId = useRef();
  const dicomDataRef = useRef(dicomData);

  const handleGenuineToolStateChange = elm => {
    const toolState = getToolState(elm, 'GenuineTool');
    if (toolState) {
      setSelectedPoint(toolState?.data?.find(state => state.handles.end.isActive) || {});
    }
  };

  const getViewSizes = () => {
    return {
      width: window.innerWidth - (fullScreen ? 0 : 230),
      height: window.innerHeight - (fullScreen ? 48 : 123),
    };
  };

  const setElementDisplayPosition = notMove => {
    const viewport = cornerstone.getViewport(element.current);
    if (!viewport) return;
    viewport.initialScale = viewport.scale;
    viewport.voi = { windowWidth: 255, windowCenter: 128 };
    cornerstone.setViewport(element.current, viewport);
  };

  const fullScreenChange = async () => {
    if (!element || !element.current || !cornerstone.getEnabledElements().length) return;
    try {
      cornerstone.resize(element.current);
      cornerstone.fitToWindow(element.current);
      setElementDisplayPosition();
    } catch (err) {
      console.log(err);
    }
  };

  const onWindowResize = () => {
    if (!element || !element.current || !cornerstone.getEnabledElements().length) return;
    cornerstone.resize(element.current);
    setElementDisplayPosition();
  };

  const onImageRendered = (e, v) => {
    if (!element || !element.current || !cornerstone.getEnabledElements().length) return;
    try {
      const viewport = cornerstone.getViewport(element.current);
      updateCurrentImageGenuine(e.detail, viewport);
      setViewportState(viewport);
      setActiveImage(viewport.images[e.detail.image.imageId]);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    dicomDataRef.current = dicomData;
  }, [dicomData]);

  const addImageTransparentMarks = imageId => {
    if (curentLoopId.current === imageId) return;
    const currentDicomData = dicomDataRef.current;
    const marks = getCurrentImageMarksForPreOrPostSlice(imageId, currentDicomData.images);
    if (marks && marks.length) {
      cornerstoneTools.clearToolState(element.current, 'GenuineTransparentTool');
      marks.forEach(points => {
        points.forEach(item => {
          cornerstoneTools.addToolState(
            element.current,
            'GenuineTransparentTool',
            createGenuieEventData(item),
          );
        });
        curentLoopId.current = imageId;
      });
    }
  };

  const updateCurrentImageGenuine = ({ image }, viewport) => {
    const imageInfo = viewport.images[image.imageId];
    if (!imageInfo) return;
    const points = imageInfo.geniusAIDataList;
    if (!imageInfo.inited) {
      if (points && points.length) {
        points.forEach(item => {
          cornerstoneTools.addToolState(
            element.current,
            'GenuineTool',
            createGenuieEventData(item),
          );
        });
      }
    }
    addImageTransparentMarks(image.imageId);
    imageInfo.inited = true;
  };

  const updateCornerstone = () => {
    try {
      const viewport = cornerstone.getViewport(element.current);
      if (!viewport) return;
      cornerstone.setViewport(element.current, viewport);
    } catch (err) {
      console.log(err);
    }
  };

  const initFindings = () => {
    try {
      cornerstoneTools.addToolForElement(element.current, ArrowTool, {});
      cornerstoneTools.addToolForElement(element.current, SelectTool, {});
      dispatch(setCanBeActive('findingToggle', false));
      cornerstoneTools.setToolEnabledForElement(element.current, 'ArrowTool');
      setActiveToolEvent();
      setTimeout(updateCornerstone, 1000);
    } catch (err) {
      console.log(err);
    }
  };

  const initGenuines = async () => {
    try {
      await cornerstoneTools.addToolForElement(element.current, GenuineTool, {
        onArrowMove: onGenuineUpdate,
        onArrowAdd: onGenuineUpdateUI,
        onArrowRemove: onGaidRemove,
        onGetDicom: onGetDicom,
        isRemoveMode: isRemoveGenius,
      });
      cornerstoneTools.setToolEnabledForElement(element.current, 'GenuineTool');
      cornerstoneTools.addToolForElement(element.current, GenuineTransparentTool, {});
      cornerstoneTools.setToolEnabledForElement(element.current, 'GenuineTransparentTool');
      setActiveToolEvent();
      setTimeout(updateCornerstone, 1000);
    } catch (err) {
      console.log(err);
    }
  };

  const onGenuineUpdateUI = (_, gaidItem) => {
    setTimeout(() => {
      try {
        setSelectedPoint(gaidItem);
        // cornerstoneTools.clearToolState(element.current, 'GenuineTransparentTool'); // Commented to keep transparent points in UI
      } catch (err) {
        console.log(err);
      }
    }, 100);
  };

  const onGenuineUpdate = (image, data) => {
    setTimeout(() => {
      try {
        const viewport = cornerstone.getViewport(element.current);
        const currentImage = viewport.images[(image?.imageId)];
        const body = {
          imageId: currentImage.id,
          data,
        };
        if (onUpdateGenuine) onUpdateGenuine(body);
        // cornerstoneTools.clearToolState(element.current, 'GenuineTransparentTool');  // Commented to keep transparent points in UI  // Commented to keep transparent points in UI
      } catch (err) {
        console.log(err);
      }
    }, 100);
  };

  const onGaidRemove = (image, data) => {
    setTimeout(() => {
      try {
        const viewport = cornerstone.getViewport(element.current);
        const currentImage = viewport.images[image.imageId];
        const body = {
          imageId: currentImage.id,
          gaidId: data?.handles?.end?.id,
        };
        if (onRemoveGaid && body?.gaidId) onRemoveGaid(body);
        // cornerstoneTools.clearToolState(element.current, 'GenuineTransparentTool');  // Commented to keep transparent points in UI
      } catch (err) {
        console.log(err);
      }
    }, 100);
  };

  const changeGenuieMode = shape => {
    setActiveTool({ ...activeTool, shape });
  };

  const onRightClick = (el, e) => {
    if (e.which !== 3) return;
    e.preventDefault();
    let tool;
    setActiveTool(item => {
      tool = item;
      return getToolByType('pan');
    });

    const handleMouseUp = (tool, e) => {
      if (e.which !== 3) return;
      el.removeEventListener('mouseup', handleMouseUp.bind(null, tool), false);
      const setTool = tool && tool.type && tool.type !== 'reset' ? tool : { type: 'none' };
      setActiveTool(setTool);
    };

    el.addEventListener('mouseup', handleMouseUp.bind(null, tool), false);
    // eslint-disable-next-line consistent-return
    return false;
  };

  const handleMouseMove = e => {
    const rect = e.target.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    setCursorPos({ x, y });
  };

  const initElement = async element => {
    try {
      await cornerstone.enable(element);
      await cornerstone.resize(element);
      element.addEventListener('cornerstoneimagerendered', onImageRendered);
      window.addEventListener('resize', onWindowResize);
      element.addEventListener('mousedown', onRightClick.bind(null, element), false);
      element.addEventListener('contextmenu', e => e.preventDefault(), false);
      if (isGenuine) {
        element.addEventListener('mousemove', handleMouseMove);
        // element.addEventListener('mouseenter', () => setShowSVG(true));
        // element.addEventListener('mouseleave', () => setShowSVG(false));
      }
    } catch (err) {
      console.log(err);
    }
  };

  const unmountElement = async () => {
    try {
      element.current.removeEventListener('cornerstoneimagerendered', onImageRendered);
      window.removeEventListener('resize', onWindowResize);
      cornerstone.disable(element.current);
    } catch (err) {
      console.log(err);
    }
  };

  const setImageStack = element => {
    const imageIds = dicomData.images.map(item => item.url);
    const stack = { currentImageIdIndex: 0, imageIds };
    cornerstoneTools.addStackStateManager(element, ['stack']);
    cornerstoneTools.addToolState(element, 'stack', stack);
  };

  const loadImages = async () => {
    if (!element.current) return;
    try {
      setImageFetch(true);
      const dicomImage = dicomData.images[0];
      const images = getDicomImagesObj(dicomData.images);
      const image = await cornerstone.loadAndCacheImage(dicomImage.url);
      const viewportOptions = { pixelReplication: false, image, dicomImage, images };
      await cornerstone.displayImage(element.current, image, viewportOptions);
    } catch (err) {
      console.log(err);
    } finally {
      setImageFetch(false);
    }
  };

  const initData = async element => {
    try {
      if (!dicomData) return;
      await element.classList.remove('maximize');
      await setImageStack(element);
      await loadImages();
      await cornerstone.fitToWindow(element);
      await cornerstone.resize(element);
      setElementDisplayPosition();
      setActiveToolEvent();
      setTimeout(initFindings, 100);
      setTimeout(initGenuines, 100);
    } catch (err) {
      console.log(err);
    }
  };

  const getGeniusData = async pointId => {
    try {
      const res = await Api.get(`/dicom/geniusData/${pointId}`);
      if (res?.data?.data) {
        res.data.data.gaid?.sort((g1, g2) => g1.order - g2.order);
        setGaidAssociations(res.data.data.gaid || []);
        setGaidDetails(res.data.data);
      }
      setActiveTool(tools.find(tool => tool.type === 'associations'));
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const removeTool = name => {
    const { current } = element;
    if (cornerstoneTools.getToolForElement(current, name)) {
      cornerstoneTools.removeToolForElement(current, name);
    }
  };

  const setActiveToolEvent = async () => {
    try {
      cornerstoneTools.setToolActive('SelectTool', {
        mouseButtonMask: 1,
        isSelectActive: true,
        handleGenuineToolStateChange,
      });
      const viewport = cornerstone.getViewport(element.current);
      if (!viewport || !activeTool.type) return;
      const { type, onTimeAction, noResetFindings, shape } = activeTool;
      const { current } = element;
      current.onclick = null;

      removeTool('Pan');
      removeTool('Wwwc');
      removeTool('Zoom');
      removeTool('ZoomMouseWheel');

      if (type === 'none' || type === 'associations' || (onTimeAction && !noResetFindings)) {
        cornerstoneTools.setToolEnabledForElement(current, 'ArrowTool');
        cornerstoneTools.setToolEnabledForElement(current, 'GenuineTool');
        cornerstoneTools.setToolEnabledForElement(current, 'GenuineTransparentTool');
      }
      if (type !== 'associations' && previousActiveTool.type === 'associations') {
        const toolState = cornerstoneTools.getToolState(current, 'GenuineTool');
        if (toolState && toolState.data) {
          const activeData = toolState.data.find(item => item.handles.end.isActive);
          if (activeData) {
            activeData.handles.end.isActive = false;
            cornerstone.updateImage(current);
          }
        }
      }

      if (type === 'reset') {
        current.classList.remove('maximize');
        setTimeout(async () => {
          await cornerstone.resize(current);
          await cornerstone.fitToWindow(current);
          await setElementDisplayPosition();
        });
      } else if (type === 'pan') {
        cornerstoneTools.addToolForElement(current, cornerstoneTools.PanTool);
        cornerstoneTools.setToolActive('Pan', { mouseButtonMask: 1 });
        cornerstoneTools.setToolActive('Pan', { mouseButtonMask: 2 });
      } else if (type === 'zoomInteractive') {
        const options = {
          configuration: {
            invert: false,
            preventZoomOutsideImage: false,
            minScale: 0.01,
            maxScale: 5.0,
          },
        };
        cornerstoneTools.addToolForElement(current, cornerstoneTools.ZoomMouseWheelTool, options);
        cornerstoneTools.addToolForElement(current, cornerstoneTools.ZoomTool, options);
        cornerstoneTools.setToolActive('ZoomMouseWheel', { mouseButtonMask: 1 });
        cornerstoneTools.setToolActive('Zoom', { mouseButtonMask: 1 });
      } else if (type === 'zoomMagnifier') {
        current.onclick = zoomImageMouseClick.bind(null, cornerstone, current, true, false);
      } else if (type === 'windowLevel') {
        cornerstoneTools.addToolForElement(current, cornerstoneTools.WwwcTool);
        cornerstoneTools.setToolActive('Wwwc', { mouseButtonMask: 1 });
      } else if (type === 'finding') {
        cornerstoneTools.setToolActive('ArrowTool', { mouseButtonMask: 1, isSelectActive: true });
      } else if (type === 'associations') {
        cornerstoneTools.setToolActive('SelectTool', {
          mouseButtonMask: 1,
          isSelectActive: true,
          usedDesignators,
          setUsedDesignators,
        });
      } else if (type === 'genuine' || type === 'RemoveGenius') {
        cornerstoneTools.setToolActive('GenuineTool', {
          mouseButtonMask: 1,
          shape,
          isRemoveMode: isRemoveGenius,
          isDisabledCreate: !hasAccess('gaid_create'),
          usedDesignators,
          setUsedDesignators,
        });
      } else if (type === 'findingToggle') {
        const tool = cornerstoneTools.getToolForElement(current, 'ArrowTool');
        const isDisabled = tool && tool.mode === 'disabled';
        dispatch(setCanBeActive(type, !isDisabled));
        if (isDisabled) {
          cornerstoneTools.setToolEnabledForElement(current, 'ArrowTool');
        } else {
          cornerstoneTools.setToolDisabledForElement(current, 'ArrowTool');
        }
        setTimeout(updateCornerstone, 500);
      } else if (type === 'findingReset') {
        cornerstoneTools.clearToolState(current, 'ArrowTool');
        setTimeout(updateCornerstone, 100);
      } else if (type === 'zoom1_1') {
        current.onclick = () => {
          maximazeElement(cornerstone, current);
          zoomImageMouseClick(cornerstone, current, true, 1);
        };
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onSaveSlice = data => {
    console.log({ data });
    onUpdateSlices({
      imageId: data.image.id,
      slices: {
        pre: data.image.preSlices,
        post: data.image.postSlices,
      },
    });
  };

  useEffect(() => {
    const currentElement = element.current;
    if (!currentElement) return;

    const handleMouseEnter = () => setShowSVG(true);
    const handleMouseLeave = () => setShowSVG(false);

    const addEventListeners = () => {
      currentElement.addEventListener('mousemove', handleMouseMove);
      currentElement.addEventListener('mouseenter', handleMouseEnter);
      currentElement.addEventListener('mouseleave', handleMouseLeave);
    };

    const removeEventListeners = () => {
      currentElement.removeEventListener('mousemove', handleMouseMove);
      currentElement.removeEventListener('mouseenter', handleMouseEnter);
      currentElement.removeEventListener('mouseleave', handleMouseLeave);
      setShowSVG(false); // Also hide the SVG when not genuine
    };

    if (isGenuine) {
      addEventListeners();
    } else {
      removeEventListeners();
    }

    return () => removeEventListeners();
  }, [isGenuine]);

  useEffect(() => {
    if (selectedPoint?.handles?.end?.id) {
      getGeniusData(selectedPoint?.handles?.end?.id);
    } else {
      setGaidAssociations([]);
      setGaidDetails(null);
      setActiveTool({ type: 'none' });
    }
  }, [selectedPoint]);

  useEffect(() => {
    initData(element.current);
    initElement(element.current);
    if (element.current) onWindowResize();
    //eslint-disable-next-line
    return () => unmountElement();
  }, []);

  useEffect(() => {
    setActiveToolEvent();
    //eslint-disable-next-line
  }, [activeTool]);

  useInitialEffect(() => {
    setTimeout(fullScreenChange, 500);
    //eslint-disable-next-line
  }, [fullScreen]);

  useEffect(() => {
    setUsedDesignators(outerUsedDesignators || []);
  }, [outerUsedDesignators]);

  return (
    <>
      <div className={`viewport-element`} style={{ ...getViewSizes() }} ref={element}>
        {imageFetch && <Loading className={'mt-5'} />}
        {dicomData && (
          <canvas
            id='viewer-canvas'
            className={`cornerstone-canvas cursor-${activeTool.cursor}${
              activeTool?.shape ? `-shape-${activeTool?.shape}` : ''
            }`}
          />
        )}
        {!dicomData && (
          <div className='viewer-no-image d-flex align-items-center justify-content-center text-white'>
            No Image Available
          </div>
        )}
      </div>
      <PrePostSlices
        onGetDicom={onGetDicom}
        onSaveSlice={onSaveSlice}
        activeImage={activeImage}
        imagesCount={dicomData.images.length}
        playing={play}
        removeMode={isRemoveGenius}
      />
      {(isGenuine || isRemoveGenius) && (
        <GenuineMode
          dicomData={dicomData}
          changeGenuieMode={changeGenuieMode}
          activeTool={activeTool}
          onUpdateSlices={onUpdateSlices}
          activeImage={activeImage}
          playing={play}
          removeMode={isRemoveGenius}
        />
      )}
      {activeTool.type === 'associations' && (
        <AssociationsBlock
          details={gaidDetails}
          selectedPoint={selectedPoint}
          associations={gaidAssociations}
        />
      )}
      {viewportState && dicomData && (
        <StackControl
          setActiveTool={setActiveTool}
          cornerstone={cornerstone}
          cornerstoneTools={cornerstoneTools}
          element={element.current}
          images={dicomData.images}
          imageType={dicomData.viewPosition}
          play={play}
          setPlay={setPlay}
          className={'right'}
          isGenuine={isGenuine}
          setSelectedPoint={setSelectedPoint}
        />
      )}
      {/* TEMPORARY HIDDEN SVG CURSOR FOR PROD */}
      {/* {isGenuine && showSVG && (
        <SvgCursor cursorPos={cursorPos} shape={activeTool?.shape} showSVG={showSVG} />
      )} */}
    </>
  );
};
