import React from 'react';
import { getImageUrl, formatDate, courseSubsStatuses } from 'utils/appHelpers';
import ClickArea from 'shared/components/ClickArea';
import { hasAccess } from 'utils/permissionHelper';
import Loading from 'shared/components/Loading';
import ListActionButtons from 'shared/components/ListActionButtons';

const headers = [
  {
    name: 'Name, Surname, Profession',
    className: 'col-2',
    key: 'learner',
  },
  {
    name: 'Subscription Plan',
    className: 'col-2',
    key: 'subscription',
  },
  {
    name: 'Date',
    className: 'col-1',
    key: 'createdAt',
  },
  {
    name: 'Type',
    className: 'col-1',
    key: 'userSubscriptionStatus',
  },
  {
    name: 'Activation Code',
    className: 'col-1',
    key: 'uniqueKey',
  },
  {
    name: 'Medical License Number',
    className: 'col mw-150px',
    key: 'medicalLicenseNumber',
  },
  {
    name: 'NPI number',
    className: 'col-1',
    key: 'npiNumber',
  },
  {
    name: 'Check Status',
    className: 'col-1 px-1',
    key: 'keyCheckStatus',
  },
  {
    name: 'Actions',
    className: 'col',
    key: '',
  },
];

const UsersLists = ({ users, order, changeOrder, selectSubscription, fetch }) => {
  if (fetch) return <Loading className='mt-5' />;

  if (!users || !users.length)
    return <div className='text-black-50 text-center mt-4'>No Results Found</div>;

  const handleOrderChange = item => {
    if (!item.key) return;
    changeOrder({
      orderKey: item.key,
      order: !order.order,
    });
  };

  const statusStates = {
    1: 'Accepted',
    2: 'Unsubscribed',
    3: 'Pendding',
    4: 'Declined',
    5: 'Expired',
    6: 'PendingExtend',
    7: 'Accepted', // AcceptedExtended
    8: 'Declined', // DeclinedExtend
  };

  const checkStatus = {
    1: 'Unknown',
    2: 'Success',
    3: 'Failure',
  };

  const actions = [
    {
      type: 'button',
      icon: 'check',
      onClick: item => selectSubscription('accept', item),
    },
    {
      type: 'button',
      icon: 'close',
      onClick: item => selectSubscription('decline', item),
    },
    {
      type: 'button',
      icon: 'edit',
      hide: item => !item.uniqueKey,
      onClick: item => selectSubscription('edit', item),
    },
  ];

  return (
    <div className='users-lists'>
      <ul className='p-0'>
        <li className='d-flex list-header align-items-center'>
          {headers.map((item, index) => (
            <ClickArea
              key={item.name + index}
              className={`d-flex text-body justify-content-between align-items-center ${
                item.className
              }`}
              onClick={handleOrderChange.bind(null, item)}
            >
              {item.name}
              {item.key && order.orderKey === item.key && (
                <button
                  className={`btn ml-2 p-0 dropdown-toggle ${order.order ? '' : 'rotate-180'}`}
                />
              )}
            </ClickArea>
          ))}
        </li>
        {users &&
          users.map(item => {
            const status = item.userSubscriptionStatus;
            const unhandled = courseSubsStatuses(status, 'pending');
            const state = statusStates[status];
            const type = [6, 7, 8].includes(status) ? 'Extend' : 'Subscription';
            return (
              <li key={item.id} className='d-flex align-items-center list-item h-not-set'>
                <div className='d-flex col-2 align-items-center'>
                  <img
                    src={getImageUrl(item.learner.imageUrl)}
                    alt={item.learner.firstname}
                    className='user-image mr-2'
                  />
                  <div>
                    <b>
                      {item.learner.firstName} {item.learner.lastName}
                    </b>
                  </div>
                </div>
                <div className='col-2'>{item.title}</div>
                <div className='col-1 px-2'>{formatDate(item.createdAt)}</div>
                <div className='col-1'>{type}</div>
                <div className='col-1'>{item.uniqueKey || '-'}</div>
                <div className='col mw-150px'>{item.medicalLicenseNumber || '-'}</div>
                <div className='col-1'>{item.npiNumber || '-'}</div>
                <div className='col-1'>{checkStatus[item.keyCheckStatus] || '-'}</div>
                {unhandled && hasAccess('subscription_request_confirm') && (
                  <div className='p-2 m-auto d-flex justify-content-start align-items-center action-buttons-container'>
                    <ListActionButtons actions={actions} item={item} />
                  </div>
                )}
                {unhandled && !hasAccess('subscription_request_confirm') && (
                  <div className='p-2 m-auto font-weight-normal'>Pending</div>
                )}
                {!unhandled && (
                  <div
                    className={`p-2 m-auto font-weight-normal ${
                      state === 'Accepted' ? 'text-success' : ''
                    } ${state === 'Declined' ? 'text-danger' : ''}`}
                  >
                    {state}
                  </div>
                )}
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default UsersLists;
