import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { hasAccess, PRoute, WithTitleRoute } from 'utils/permissionHelper';
import { useSelector } from 'react-redux';
import SideBar from './components/SideBar/index';
import WithGlobalScrollTop from 'HOC/WithGlobalScrollTop';

import Home from './routes/Home';
import Subscriptions from './routes/Subscriptions';
import SubscriptionNew from './routes/SubscriptionNew';
import Courses from './routes/Courses';
import CourseView from './routes/CourseView';
import CourseNew from './routes/CourseNew';
import CourseStatistics from './routes/CourseStatistics';
import Users from './routes/Users';
import UserView from './routes/UserView';
import UserNew from './routes/UserNew';
import UserProgress from './routes/UserProgress';
import Statistics from './routes/Statistics';
import Admins from './routes/Admins';
import AdminsRoles from './routes/AdminsRoles';
import AdminsRolesNew from './routes/AdminsRolesNew';
import AdminsRoleView from './routes/AdminsRoleView';
import AdminsRoleViewUsers from './routes/AdminsRoleViewUsers';
import AdminNew from './routes/AdminNew';
import AdminView from './routes/AdminView';
import Icons from './routes/Icons';
import Authors from './routes/Authors';
import AuthorNew from './routes/AuthorNew';
import UserTestsHistory from './routes/UserTestsHistory';
import UserCertificate from './routes/UserCertificate';
import SubscriptionRequest from './routes/SubscriptionRequest';
import Notifications from './components/Notifications';
import Tickets from './routes/Tickets';
import Profile from './routes/Profile';
import NotificationsManage from './routes/NotificationsManage';
import CourseViewUsers from './routes/CourseViewUsers';
import UserActionsHistory from './routes/UserActionsHistory';
import SystemEmails from './routes/SystemEmails';
import ReportImport from './routes/ReportImport';
import ReportExport from './routes/ReportExport';
import SystemEmailEdit from './routes/SystemEmailEdit';
import Lessons from './routes/Lessons';
import LessonsNew from './routes/LessonsNew';
import LessonView from './routes/LessonView';
import LessonsHeatmaps from './routes/LessonsHeatmaps';
import Licenses from './routes/Licenses';
import LicensesNew from './routes/LicensesNew';
import LicensesView from './routes/LicensesView';
import EntityNew from './routes/EntityNew';
import Entities from './routes/Entities';
import EntityView from './routes/EntityView';
import EntityUserNew from './routes/EntityUserNew';
import EntityUsers from './routes/EntityUsers';
import SubscriptionView from './routes/SubscriptionView';
import Reports from './routes/Reports';
import ReportsManage from './routes/ReportsManage';
import Certificates from './routes/Certificates';
import CertificatesNew from './routes/CertificatesNew';
import SystemLogs from './routes/SystemLogs';
import Events from './routes/Events';
import EventsNew from './routes/EventsNew';
import ProtocolsNew from './routes/ProtocolsNew';
import Protocols from './routes/Protocols';
import DevicesPairs from './routes/DevicesPairs';
import Devices from './routes/Devices';
import EventView from './routes/EventView';
import Cases from './routes/Cases';
import CasesNew from './routes/CasesNew';
import CaseView from './routes/CaseView';
import WarehouseCasesNew from './routes/WarehouseCasesNew';
import WarehouseCases from './routes/WarehouseCases';
import WarehouseCaseView from './routes/WarehouseCaseView';
import CaseViewGenuine from './routes/CaseViewGenuine';
import CoursePreview from './routes/CoursePreview';
import LessonPreview from './routes/LessonPreview';
import CaseBiradsNew from './routes/CaseBiradsNew';
import RoomsNew from './routes/RoomsNew';
import Rooms from './routes/Rooms';
import SurveysNew from './routes/SurveysNew';
import Surveys from './routes/Surveys';
import SurveyList from 'app/Public/routes/SurveyList';
import Countries from './routes/Countries';
import CountriesNew from './routes/CountriesNew';
import Regions from './routes/Regions';
import RegionsView from './routes/RegionsView';
import RegionsNew from './routes/RegionsNew';
import RoomPreview from './routes/RoomPreview';
import NoAccess from 'shared/components/NoAccess';
import { CourseQuizConfiguration } from './routes/CourseQuizConfiguration';
import { CourseQuizView } from './routes/CourseQuizView';
import { CourseQuizQuestionGroupsConfig } from './routes/CourseQuizQuestionGroupsConfig';
import { CourseQuizQuestionGroupsView } from './routes/CourseQuizQuestionGroupsView';
import Products from './routes/Products';
import ProductNew from './routes/ProductNew';
import ProductView from './routes/ProductView';
import ProductPageNew from './routes/ProductPageNew';
import ProductPagePreview from './routes/ProductPagePreview';
import ProductUsersList from './routes/ProductUsersList';
import MarketingSpotlights from './routes/MarketingSpotlights';
import MarketingSpotlightNew from './routes/MarketingSpotlightNew';

const Main = () => {
  const uploader = useSelector(state => state.uploader);
  const isKolUser = hasAccess('kol_user');
  const redirect = isKolUser
    ? '/subscriptions/all'
    : hasAccess('entity_manager')
    ? '/entity'
    : hasAccess('home_view')
    ? '/home'
    : '/';
  const hasUploadFiles = !!uploader?.files?.length;
  const location = useLocation();

  const productPagePreviewPattern = /^\/product\/([^\/]+)\/product-page\/([^\/]+)\/preview$/;

  const noSidebar = location.pathname.match(productPagePreviewPattern);

  // prettier-ignore
  return (
    <div className='container-fluid p-0'>
    <WithGlobalScrollTop />
      <div>
        <div id='main-portal' />
        {!noSidebar &&<nav id='main_sidebar' className='d-flex flex-column justify-content-between p-0 sidebar'>
          <SideBar />
        </nav>}
        <main id='main' role='main' className={`p-0 min-vh-100 ${noSidebar && 'no-sidebar'} ${isKolUser ? 'kol' : ''} ${hasUploadFiles ? 'has-files' : ''}`}>
          <Notifications className={noSidebar && 'no-display'} />
          <Switch>
            <WithTitleRoute path='/no-access' component={NoAccess} />
            <WithTitleRoute path='/survey/list' component={SurveyList} />
            <WithTitleRoute path='/profile/notifications' component={NotificationsManage} />
            <WithTitleRoute path='/profile' component={Profile} />
            <PRoute access='home_view' exact path='/home' component={Home} />
            <PRoute access='subscriptions_view' exact path='/subscriptions/new' component={SubscriptionNew} />
            <PRoute access='subscriptions_join_request_view' exact path='/subscription/requests/:filter?' component={SubscriptionRequest} />
            <PRoute access='subscriptions_list_view' exact path='/subscriptions/:filter?' component={Subscriptions} />
            <PRoute access='subscription_view' exact path='/subscription/:id/view' component={SubscriptionView} />
            <PRoute access='subscription_view' exact path='/subscription/:id/edit' component={SubscriptionNew} />
            <PRoute access='courses_statistics_view' exact path='/courses/statistics' component={CourseStatistics} />
            <PRoute access='courses_create' exact path='/courses/new' component={CourseNew} />
            <PRoute access='courses_create' exact path='/courses/preview/:type?' component={CoursePreview} />
            <PRoute access='courses_list_view' exact path='/courses/:filter?/:subFilter?' component={Courses} />
            <PRoute access='course_view' exact path='/course/:id/edit' component={CourseNew} />
            {/* TEMPORARY HIDDEN FOR PROD */}
            {/* <PRoute access='products_view' exact path='/products/:filter?/:subFilter?' component={Products} />
            <PRoute access='products_view' exact path='/product/:id/view' component={ProductView} />
            <PRoute access='products_create' exact path='/product/new' component={ProductNew} />
            <PRoute access='products_create' exact path='/product/:id/edit' component={ProductNew} />
            <PRoute access='products_create' exact path='/product/:productId/product-page/new' component={ProductPageNew} />
            <PRoute access='products_create' exact path='/product/:productId/product-page/:id/edit' component={ProductPageNew} />
            <PRoute access='products_view' exact path='/product/:productId/product-page/:id/preview' component={ProductPagePreview} />
            <PRoute access='products_view' exact path='/product/:productId/users' component={ProductUsersList} /> */}
            <PRoute access='quiz_create' exact path='/course/:id/quiz-config' component={CourseQuizConfiguration} />
            <PRoute access='quiz_view' exact path='/course/:id/quiz-view' component={CourseQuizView} />
            <PRoute access='quiz_create' exact path='/course/:id/quiz/question-groups/config' component={CourseQuizQuestionGroupsConfig} />
            <PRoute access='quiz_view' exact path='/course/:id/quiz/question-groups' component={CourseQuizQuestionGroupsView} />
            <PRoute access='course_view' exact path='/course/:id/users' component={CourseViewUsers} />
            <PRoute access='course_view' exact path='/course/:id/view' component={CourseView} />
            <PRoute access='users_create' exact path='/users/new' component={UserNew} />
            <PRoute access='users_list_view' exact path='/users/all' component={Users} />
            <PRoute access='user_progress_view' exact path='/user/:id/progress' component={UserProgress} />
            <PRoute access='user_test_history_view' exact path='/user/:id/tests' component={UserTestsHistory} />
            <PRoute access='user_view' exact path='/user/:id/actions' component={UserActionsHistory} />
            <PRoute access='user_view' exact path='/user/:id/certificate' component={UserCertificate} />
            <PRoute access='user_view' exact path='/user/:id/:type?' component={UserView} />
            <PRoute access='admins_list_view' exact path='/admins/all' component={Admins} />
            <PRoute access='admin_create' exact path='/admins/new' component={AdminNew} />
            <PRoute access='admins_roles_view' exact path='/roles/all' component={AdminsRoles} />
            <PRoute access='admins_roles_create' exact path='/roles/new' component={AdminsRolesNew} />
            <PRoute access='admins_roles_create' exact path='/roles/:id/edit' component={AdminsRolesNew} />
            <PRoute access='admins_roles_view' exact path='/roles/:id/view' component={AdminsRoleView} />
            <PRoute access='admins_roles_view' exact path='/roles/:id/users' component={AdminsRoleViewUsers} />
            <PRoute access='admin_view' exact path='/admin/:id/:type?' component={AdminView} />
            <PRoute access='author_create' exact path='/authors/new' component={AuthorNew} />
            <PRoute access='authors_list_view' exact path='/authors/:filter?/:subFilter?' component={Authors} />
            <PRoute access='author_view' exact path='/author/:id' component={AuthorNew} />
            <PRoute access='statistics_view' exact path='/statistics' component={Statistics} />
            <PRoute access='support_tickets_view' exact path='/support/tickets/:filter?' component={Tickets} />
            <PRoute access='system_emails_view' exact path='/system/emails' component={SystemEmails} />
            <PRoute access='system_emails_edit' exact path='/system/emails/:type' component={SystemEmailEdit} />
            {/* TEMPORARY HIDDEN MARKETING SPOTLIGHT */}
            {/* <PRoute access='system_spotlights_view' exact path='/system/marketing-spotlights' component={MarketingSpotlights} />
            <PRoute access='system_spotlights_edit' exact path='/system/marketing-spotlight/edit/:id' component={MarketingSpotlightNew} />
            <PRoute access='system_spotlights_edit' exact path='/system/marketing-spotlight/new' component={MarketingSpotlightNew} /> */}
            <PRoute access='reports_export' exact path='/reports/:type' component={Reports} />
            <PRoute access='reports_export' exact path='/reports/:type/import' component={ReportImport} />
            <PRoute access='reports_export' exact path='/reports/:type/export' component={ReportExport} />
            <PRoute access='reports_export' exact path='/reports/:type/create' component={ReportsManage} />
            <PRoute access='reports_export' exact path='/reports/:type/edit/:id' component={ReportsManage} />
            <PRoute access='lessons_list_view' exact path='/lesson/:id/heatmaps' component={LessonsHeatmaps} />
            <PRoute access='lessons_list_view' exact path='/lesson/:id/view' component={LessonView} />
            <PRoute access='lessons_create' exact path='/lesson/:id/edit' component={LessonsNew} />
            <PRoute access='lessons_create' exact path='/lessons/new' component={LessonsNew} />
            <PRoute access='lessons_list_view' exact path='/lessons/preview/:type?' component={LessonPreview} />
            <PRoute access='lessons_list_view' exact path='/lessons/:filter?' component={Lessons} />
            <PRoute access='certificates_create' exact path='/certificate/new' component={CertificatesNew} />
            <PRoute access='certificates_create' exact path='/certificate/:id' component={CertificatesNew} />
            <PRoute access='certificates_list_view' exact path='/certificates/:filter?' component={Certificates} />
            <PRoute access='cases_create' exact path='/cases/new' component={CasesNew} />
            <PRoute access='cases_list_view' exact path='/cases/:filter?' component={Cases} />
            <PRoute access='cases_list_view' exact path='/case/:id/birads' component={CaseBiradsNew} />
            <PRoute access='cases_list_view' exact path='/case/:id/genius/:dicomId' component={CaseViewGenuine} />
            <PRoute access='cases_list_view' exact path='/case/:id' component={CaseView} />
            <PRoute access='warehouse_cases_create' exact path='/warehouse/cases/new' component={WarehouseCasesNew} />
            <PRoute access='warehouse_cases_list_view' exact path='/warehouse/cases/:filter?' component={WarehouseCases} />
            <PRoute access='warehouse_cases_list_view' exact path='/warehouse/case/:id' component={WarehouseCaseView} />
            <PRoute access='license_create' exact path='/licenses/new/:entityId?' component={LicensesNew} />
            <PRoute access='license_list_view' exact path='/licenses/:filter?/:entityId?' component={Licenses} />
            <PRoute access='license_list_view' exact path='/license/:id' component={LicensesView} />
            <PRoute access='entity_create' exact path='/entity/new' component={EntityNew} />
            <PRoute access='entity_create' exact path='/entity/:id/edit' component={EntityNew} />
            <PRoute access='entity_list_view' exact path='/entities' component={Entities} />
            <PRoute access='entity_manager' exact path='/entity/' component={EntityView} />
            <PRoute access='entity_list_view' exact path='/entity/:id' component={EntityView} />
            <PRoute access='entity_user_create' exact path='/entity/:id/invite' component={EntityUserNew} />
            <PRoute access='entity_user_list_view' exact path='/entity/:id/users' component={EntityUsers} />
            <PRoute access='entity_user_list_view' exact path='/entity/:id/licenses/:entityId' component={Licenses} />
            <PRoute access='system_logs_view' exact path='/system/logs/:filter?' component={SystemLogs} />
            <PRoute access='system_countries_view' exact path='/system/countries' component={Countries} />
            <PRoute access='system_regions_view' exact path='/system/regions' component={Regions} />
            <PRoute access='system_countries_view' exact path='/system/regions/:id/view' component={RegionsView} />
            <PRoute access='system_regions_create' exact path='/system/regions/new' component={RegionsNew} />
            <PRoute access='system_regions_create' exact path='/system/regions/:id/edit' component={RegionsNew} />
            <PRoute access='system_countries_create' exact path='/system/countries/new' component={CountriesNew} />
            <PRoute access='system_countries_create' exact path='/system/countries/:id/edit' component={CountriesNew} />
            <PRoute access='event_create' exact path='/events/new' component={EventsNew} />
            <PRoute access='event_create' exact path='/events/:id/edit' component={EventsNew} />
            <PRoute access='events_list_view' exact path='/event/:id' component={EventView} />
            <PRoute access='events_list_view' exact path='/events/:filter?' component={Events} />
            <PRoute access='protocol_create' exact path='/protocols/new' component={ProtocolsNew} />
            <PRoute access='protocol_create' exact path='/protocol/:id/edit' component={ProtocolsNew} />
            <PRoute access='protocols_list_view' exact path='/protocols/:filter?' component={Protocols} />
            <PRoute access='devices_list_pairs_view' exact path='/devices/pairs' component={DevicesPairs} />
            <PRoute access='devices_list_view' exact path='/devices/:filter?' component={Devices} />
            <PRoute access='room_360_create' exact path='/rooms/new' component={RoomsNew} />
            <PRoute access='room_360_create' exact path='/room/:id/edit' component={RoomsNew} />
            <PRoute access='room_360_create' exact path='/room/preview' component={RoomPreview} />
            <PRoute access='room_360_list' exact path='/rooms' component={Rooms} />
            <PRoute access='survey_create' exact path='/survey/new' component={SurveysNew} />
            <PRoute access='survey_create' exact path='/survey/:id/edit' component={SurveysNew} />
            <PRoute access='surveys_list_view' exact path='/surveys' component={Surveys} />
            <PRoute access='icons_view' exact path='/icons' component={Icons} />
            <Redirect from='*' to={redirect} />
          </Switch>
        </main>
      </div>
    </div>
  );
};

export default Main;
