import React, { useLayoutEffect, useState } from 'react';
import EditingInput from 'shared/components/editingForms/EditingInput';
import EditingSelect from 'shared/components/editingForms/EditingSelect';
import { states } from 'app/Main/configs';
import { HOST, countries } from 'configs';
import UploadImageRow from 'shared/components/UploadImageRow';
import { CloseCircleFilledIcon, IconCheckCircle, IconWarning } from 'shared/components/Icons';
import Button from 'shared/components/Button';
import { useSnackbar } from 'notistack';
import { getError } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import { EditingInputWithPostfix } from 'shared/components/EditingInputWithPostfix';

const getUrlNotes = serverHostIP => {
  return {
    warning: {
      message: `The domain name you insert into URL field should be configured to point to IP Address ${serverHostIP}.`,
      icon: <IconWarning className='mr-1' />,
    },
    succeed: {
      message: 'The url validation succeed.',
      icon: <IconCheckCircle className='mr-1' />,
    },
    rejected: {
      message: 'The url validation rejected.',
      icon: <CloseCircleFilledIcon className='mr-1' />,
    },
  };
};

const EventOrganizerStep = ({
  form,
  setForm,
  data,
  setData,
  isWorkshop,
  isEditing,
  serverHostIP,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isChecking, setIsChecking] = useState(false);
  const [urlNotes, setUrlNotes] = useState(getUrlNotes(serverHostIP));

  const tempGeneratedUrl = HOST.API.LEARNER_DOMAIN + form.name.replaceAll(' ', '_');

  const handleChange = ({ target }) => {
    const { name, value, checked, type } = target;
    const newForm = { ...form };
    const val = type === 'checkbox' ? (checked ? 1 : 0) : value;
    newForm[name] = val;
    if (name === 'webinarUrl') {
      if (value === data.initialWebinarUrl || value === HOST.API.LEARNER_DOMAIN) {
        setData({ ...data, urlCheckState: 'succeed' });
      } else {
        setData({ ...data, urlCheckState: 'warning' });
      }
    }
    setForm(newForm);
  };

  const onSuccessLogoUpload = image => {
    setData({ ...data, organazerImage: image });
    setForm({ ...form, organizerLogoId: image.id });
  };

  const onSuccessBannerUpload = async (index, image) => {
    const tempForm = { ...form };
    tempForm.banners[index] = image.id;
    setForm(tempForm);
    const tempData = { ...data };
    tempData.banners = tempData.banners || [];
    tempData.banners[index] = image.url;
    setData(tempData);
  };

  const addBanner = () => {
    setForm({ ...form, banners: [...form.banners, 0] });
    setData({ ...data, banners: [...data.banners, 0] });
  };

  const removeBanner = index => {
    const tmpFormBanner = [...form.banners];
    tmpFormBanner.splice(index, 1);
    const tmpDataBanner = [...data.banners];
    tmpDataBanner.splice(index, 1);
    setForm({ ...form, banners: tmpFormBanner });
    setData({ ...data, banners: tmpDataBanner });
  };

  const handleCheckDomain = async () => {
    try {
      setIsChecking(true);
      await Api.post('/wizard/event/check-domain', { domain: form.webinarUrl });
      setData({ ...data, urlCheckState: 'succeed' });
    } catch (err) {
      setData({ ...data, urlCheckState: 'rejected' });
      setUrlNotes({ ...urlNotes, rejected: { ...urlNotes.rejected, message: getError(err) } });
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setIsChecking(false);
    }
  };

  const handleResetWebinarUrl = () => {
    const resetUrl = HOST.API.LEARNER_DOMAIN;
    setForm({ ...form, webinarUrl: resetUrl });
    if (isEditing) {
      setData({ ...data, urlCheckState: 'succeed' });
    }
  };

  useLayoutEffect(() => {
    const isDefaultUrl =
      form.webinarUrl === HOST.API.LEARNER_DOMAIN ||
      form.webinarUrl === data?.initialWebinarUrl?.split('/events')?.[0];
    setForm({ ...form, organizer: 'Hologic' });
    setData({
      ...data,
      urlCheckState: isDefaultUrl ? 'succeed' : 'warning',
    });
  }, []);

  const isBranded = Number(form.realization) === 1;
  const isCountryUs = form.country === '226';

  return (
    <div className='px-2'>
      <h6 className='color-blue mb-3'>Organizer Info</h6>
      <div className='col-9 p-0 user-creation-form'>
        <ul className='p-0 mb-0'>
          <li className='d-flex align-items-center'>
            <div className='col-3 pl-0'>
              <label className='m-0'>Organizer*:</label>
            </div>
            <EditingInput
              type='text'
              name='organizer'
              value={form.organizer}
              onChange={handleChange}
              className='p-0'
              placeholder='Enter Organizer'
              required
              editing={!isBranded}
              maxLength='180'
            />
          </li>
          <UploadImageRow
            title='Organizer Logo*:'
            image={data.organazerImage && data.organazerImage.url}
            name={'organazerLogoImg'}
            onSuccessUpload={onSuccessLogoUpload}
          />
          <li className={`d-flex ${isBranded ? 'align-items-center' : ''}`}>
            <div className='col-3 pl-0 pt-1'>
              <label className='m-0'>URL*:</label>
            </div>
            <div className='w-100'>
              <div className='form-input-row w-100 d-flex'>
                {isBranded ? (
                  <EditingInput
                    type='text'
                    name='webinarUrl'
                    value={form.webinarUrl || tempGeneratedUrl}
                    onChange={handleChange}
                    placeholder='Enter Url'
                    className='p-0'
                    editing={!isBranded}
                    required
                    access
                  />
                ) : (
                  <EditingInputWithPostfix
                    type='text'
                    name='webinarUrl'
                    onValueChange={handleChange}
                    value={form.webinarUrl}
                    placeholder='Enter Url'
                    postfix={form.webinarUrlPath}
                  />
                )}
                {!isBranded && (
                  <div className='form-input-row-actions'>
                    <Button
                      disabled={isChecking || data.urlCheckState === 'succeed'}
                      onClick={handleCheckDomain}
                      className='btn-primary right-no-radius'
                    >
                      Check
                    </Button>
                    <Button
                      onClick={handleResetWebinarUrl}
                      className='btn-outline-blue left-no-radius'
                    >
                      Reset
                    </Button>
                  </div>
                )}
              </div>
              {!isBranded && (
                <p className='mb-0 fw-400 mt-2 fz-12 d-flex align-items-center'>
                  {urlNotes[data.urlCheckState].icon} {urlNotes[data.urlCheckState].message}
                </p>
              )}
            </div>
          </li>
          <li className='d-flex align-items-center'>
            <div className='col-3 pl-0'>
              <label className='m-0'>Address*:</label>
            </div>
            <EditingInput
              type='text'
              name='venue'
              value={form.venue}
              onChange={handleChange}
              className='p-0'
              placeholder='Enter Address'
              editing={true}
              access={true}
              maxLength='255'
              required={true}
            />
          </li>
          <li className='d-flex align-items-center'>
            <div className='col-3 pl-0'>
              <label className='m-0'>City*:</label>
            </div>
            <EditingInput
              type='text'
              name='city'
              value={form.city}
              onChange={handleChange}
              className='p-0'
              placeholder='Enter City'
              editing={true}
              access={true}
              maxLength='255'
              required={true}
            />
          </li>
          <li className='d-flex align-items-center'>
            <div className='col-3 pl-0'>
              <label className='m-0'>State*:</label>
            </div>
            {isCountryUs ? (
              <EditingSelect
                name='state'
                onChange={handleChange}
                value={form.state}
                items={states}
                useProperty='code'
                displayProperty='name'
                required={true}
                hideDefault={true}
                valueClassName='pl-0'
                editing={true}
              />
            ) : (
              <EditingInput
                type='text'
                name='state'
                value={form.state}
                onChange={handleChange}
                className='p-0'
                placeholder='Enter State'
                editing={true}
                access={true}
                maxLength='255'
                required={true}
              />
            )}
          </li>
          <li className='d-flex align-items-center'>
            <div className='col-3 pl-0'>
              <label className='m-0'>Country*:</label>
            </div>
            <EditingSelect
              name='country'
              onChange={handleChange}
              value={form.country}
              items={countries}
              useProperty='id'
              displayProperty='name'
              required={true}
              hideDefault={true}
              valueClassName='pl-0'
              editing={true}
            />
          </li>
          <li className='d-flex align-items-center'>
            <div className='col-3 pl-0'>
              <label className='m-0'>Participants Number*:</label>
            </div>
            <EditingInput
              type='number'
              name='capacity'
              value={form.capacity}
              onChange={handleChange}
              className='p-0'
              placeholder='Empty For No Limitation'
              editing={true}
              access={true}
            />
          </li>
        </ul>
      </div>
      <h6 className='color-blue mb-3 mt-4'>Banner Images</h6>
      <div className='col-9 p-0 user-creation-form'>
        <ul className='p-0 mb-0'>
          {data.banners.map((item, index) => {
            const minCount = form.showToLearner ? 2 : 1;
            const required = form.showToLearner && index < minCount;
            const image =
              data.banners && data.banners[index] && data.banners[index] !== 0
                ? data.banners[index]
                : null;
            const showRemove = index + 1 > minCount;
            return (
              <UploadImageRow
                index={index}
                key={index}
                required={required}
                hideVisual={required}
                title={`Banner Image ${index + 1}${required ? '*' : ''}:`}
                image={image}
                name={`banner-${index}`}
                onSuccessUpload={onSuccessBannerUpload.bind(null, index)}
                showRemove={showRemove}
                removeBanner={removeBanner.bind(null, index)}
              />
            );
          })}
        </ul>
        <div className='text-right'>
          <button type='button' className='btn btn-outline-blue add-new-frames' onClick={addBanner}>
            Add Image +
          </button>
        </div>
      </div>
    </div>
  );
};

export default EventOrganizerStep;
