import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';

import { Api } from 'utils/connectors';
import { getError } from 'utils/appHelpers';
import {
  affirmProneBiopsyUsage,
  appMarkers,
  awsVersions,
  biopsyUsage,
  breastSizes,
  clockTypes,
  decors,
  discoveryDXAUsage,
  ethnicities,
  faxitronCoreSpecimenUsage,
  faxitronPathSpecimenUsage,
  floorTypes,
  fluoroscanMiniCArmUsage,
  gantryModels,
  johnnyTypes,
  operatorModels,
  optionsToList,
  pathologies,
  patientModels,
  patientPaddles,
  patientPositions,
  roomScanAngels,
  skinColors,
  transitionEffects,
  wallTypes,
} from '../Rooms/utils';

import EditingSelect from 'shared/components/editingForms/EditingSelect';
import EditingInput from 'shared/components/editingForms/EditingInput';
import RadioBox from 'shared/components/RadioBox';
import { SwitchIOSLike } from 'shared/components/SwitchIOSLike';
import SmallCounter from 'shared/components/SmallCounter';
import { IconWomanModel } from 'shared/components/Icons';
import ReuseTabExternalLink from 'shared/components/ReuseTabExternalLink';

const RoomsNew = ({ history, match, location }) => {
  const { id } = match.params;
  const sampleRoomId = location?.state?.sampleId;
  const { enqueueSnackbar } = useSnackbar();
  const [fetch, setFetch] = useState(false);
  const [data, setData] = useState();
  const [arAppNumOfMass, setArAppNumOfMass] = useState(0);
  const [affirm, setAffirm] = useState({});
  const [discoveryDXA, setDiscoveryDXA] = useState({});
  const [fluroscanMiniUse, setFluroscanMiniUse] = useState({});
  const [faxitronPath, setFaxitronPath] = useState({});
  const [faxitronCore, setFaxitronCore] = useState({});

  const [form, setForm] = useState({
    model: '',
    operator: '',
    hasOperator: 0,
    gantry: 'Dimensions with Biopsy',
    awsVersion: 'v10.248',
    position: '',
    installedPaddle: '',
    ciEffectiveScanTime: '',
    ciWaitTime: '',
    scanAngle: 0,
    biopsyUsage: 1,
    affirmProneBiopsyUsage: 0,
    transitionEffect: '',
    arAppModel: '',
    arAppMarker: '',
    arAppNumOfMass,
    arAppDiffFromWeb: 0,
    roomName: '',
    patientHeight: 0,
    patientWeight: 0,
    operatorHeight: 0,
    operatorWeight: 0,
    isClockActive: 0,
    decor: 0,
  });

  const handleChange = ({ target }) => {
    const { name, value, type, checked } = target;
    const result = type === 'checkbox' ? checked : value;
    let gantryAddObj =
      name === 'gantry' ? { biopsyUsage: Number(value === 'Dimensions with Biopsy') } : {};
    setForm({ ...form, [name]: result, ...gantryAddObj });
  };

  const handleRadioChange = (name, value) => {
    setForm({ ...form, [name]: value });
  };

  const getRoomData = async currentId => {
    try {
      const { data } = await Api.get(`/360rooms/getroom/${currentId}`);
      const settingData = {
        ...data.data,
        operator: data.data.operatorName || data.data.operator,
        gantry: data.data.gantryName || data.data.gantry,
        roomName: data.data.roomName || data.data.name,
      };
      setForm(prev => ({ ...prev, ...settingData }));
      setData(settingData);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      setFetch(true);
      const body = { ...form, originId: id ? id : undefined };
      if (!body.arAppDiffFromWeb) body.arAppModel = body.model;
      const endpoint = id ? '/360rooms/update' : '/360rooms/add';
      await Api.post(endpoint, { ...body, gantry: 'Standard Dimensions' }); // ** NOTE: added temporary to prevent 400 err, need to change
      enqueueSnackbar(`Room successfully ${id ? 'updated' : 'created'}`, { variant: 'success' });
      history.push('/rooms');
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetch(false);
    }
  };

  const handleSwitch = name => {
    setForm(prev => ({ ...prev, [name]: prev[name] ? 0 : 1 }));
  };

  const roomParams = `biopsyUsage=${form.biopsyUsage},hasOperator=${
    form.hasOperator
  },transitionEffect=${form.transitionEffect},ciWaitTime=${form.ciWaitTime},ciEffectiveScanTime=${
    form.ciEffectiveScanTime
  },
  `;

  useEffect(() => {
    const currentId = id || sampleRoomId;
    if (currentId) getRoomData(currentId);
    //eslint-disable-next-line
  }, []);

  return (
    <div>
      <div className='users-header d-flex justify-content-between align-items-center'>
        <div className='d-flex breadcrumbs'>
          <Link to='/rooms'>Rooms</Link>
          <span className='mx-2'>/</span>
          <span>360 Room Configuration</span>
        </div>
        <ReuseTabExternalLink
          target='my_unique_tab'
          href={`${window.origin}/room/preview?${roomParams}`}
          className='btn btn-outline-blue room_preview_btn'
        >
          Preview
        </ReuseTabExternalLink>
      </div>
      <div className='col-12 py-3 has-header d-flex'>
        <form onSubmit={handleSubmit} className='col-8 p-0 user-creation-form'>
          <ul className='p-0 mb-0'>
            <li className='d-flex align-items-center'>
              <div className='col-3 pl-0'>
                <label className='m-0'>Room Name:</label>
              </div>
              <EditingInput
                type='text'
                name='roomName'
                value={form.roomName}
                onChange={handleChange}
                className='p-0'
                placeholder='Enter Name'
                editing={true}
                access={true}
                min={0}
              />
            </li>
          </ul>
          <h6 className=' font-weight-bold color-blue mt-5 mb-3'>Patient</h6>
          <ul className='p-0 mb-0'>
            <li className='d-flex align-items-center'>
              <div className='col-3 pl-0'>
                <label className='m-0'>Patient Model:</label>
              </div>
              <EditingSelect
                emptyValueClassName='empty_label_lightgray'
                name='model'
                label='Choose Model'
                onChange={handleChange}
                value={form.model}
                items={optionsToList(patientModels)}
                useProperty='id'
                displayProperty='name'
                required={true}
                editing={true}
              />
            </li>
            <li className='d-flex align-items-center'>
              <div className='col-3 pl-0'>
                <label className='m-0'>Johnny:</label>
              </div>
              <EditingSelect
                emptyValueClassName='empty_label_lightgray'
                name='johnny'
                label='Choose Johnny'
                onChange={handleChange}
                value={form.johnny}
                items={optionsToList(johnnyTypes)}
                useProperty='id'
                displayProperty='name'
                required={true}
                editing={true}
              />
            </li>
            <div className='d-flex two_items_in_form_row'>
              <li className='d-flex justify-content-between align-items-center w-50'>
                <div className='col-6 pl-0'>
                  <label className='m-0'>Patient Height:</label>
                </div>
                <SmallCounter
                  customClasses='pl-2'
                  count={form.patientHeight}
                  handleIncreaseCount={() =>
                    setForm(prev => ({ ...prev, patientHeight: form.patientHeight + 1 }))
                  }
                  handleDecreaseCount={() =>
                    setForm(prev => ({ ...prev, patientHeight: form.patientHeight - 1 }))
                  }
                />
                <span className='font-weight-normal form_counter_after_text'>ft.</span>
              </li>
              <li className='d-flex justify-content-between align-items-center w-50'>
                <div className='col-6 pl-0'>
                  <label className='m-0 text_no_wrap'>Patient Weight:</label>
                </div>
                <SmallCounter
                  count={form.patientWeight}
                  handleIncreaseCount={() =>
                    setForm(prev => ({ ...prev, patientWeight: form.patientWeight + 1 }))
                  }
                  handleDecreaseCount={() =>
                    setForm(prev => ({ ...prev, patientWeight: form.patientWeight - 1 }))
                  }
                />
                <span className='font-weight-normal form_counter_after_text'>lb.</span>
              </li>
            </div>
            <li className='d-flex align-items-center'>
              <div className='col-3 pl-0'>
                <label className='m-0'>Breast Cup Size:</label>
              </div>
              <EditingSelect
                emptyValueClassName='empty_label_lightgray'
                name='breastSize'
                label='Choose Size'
                onChange={handleChange}
                value={form.breastSize}
                items={optionsToList(breastSizes)}
                useProperty='id'
                displayProperty='name'
                required={true}
                editing={true}
              />
            </li>
            <li className='d-flex align-items-center'>
              <div className='col-3 pl-0'>
                <label className='m-0'>Patient Skin Color:</label>
              </div>
              <EditingSelect
                emptyValueClassName='empty_label_lightgray'
                name='patientSkinColor'
                label='Choose Skin Color'
                onChange={handleChange}
                value={form.patientSkinColor}
                items={optionsToList(skinColors)}
                useProperty='id'
                displayProperty='name'
                required={true}
                editing={true}
              />
            </li>
            <li className='d-flex align-items-center'>
              <div className='col-3 pl-0'>
                <label className='m-0'>Patient Ethnicity:</label>
              </div>
              <EditingSelect
                emptyValueClassName='empty_label_lightgray'
                name='patientEthnicity'
                label='Choose Ethnicity'
                onChange={handleChange}
                value={form.patientEthnicity}
                items={optionsToList(ethnicities)}
                useProperty='id'
                displayProperty='name'
                required={true}
                editing={true}
              />
            </li>
          </ul>
          <h6 className=' font-weight-bold color-blue mt-5 mb-3'>Operator</h6>
          <ul className='p-0 mb-0'>
            <li className='d-flex align-items-center'>
              <div className='col-3 pl-0'>
                <label className='m-0'>Operator Model:</label>
              </div>
              <div className='switch_container'>
                <SwitchIOSLike
                  handleChange={() => handleSwitch('hasOperator')}
                  checkedState={form.hasOperator}
                />
              </div>
              <EditingSelect
                disabled={!form.hasOperator}
                emptyValueClassName='empty_label_lightgray'
                name='operator'
                label='Choose Model'
                onChange={handleChange}
                value={form.operator}
                items={optionsToList(operatorModels)} // need to change
                useProperty='id'
                displayProperty='name'
                required={true}
                editing={true}
              />
            </li>
            <div className='d-flex two_items_in_form_row'>
              <li className='d-flex justify-content-between align-items-center w-50'>
                <div className='col-6 pl-0'>
                  <label className='m-0 text_no_wrap'>Operator Height:</label>
                </div>
                <SmallCounter
                  customClasses='pl-2'
                  count={form.operatorHeight}
                  handleIncreaseCount={() =>
                    setForm(prev => ({ ...prev, operatorHeight: form.operatorHeight + 1 }))
                  }
                  handleDecreaseCount={() =>
                    setForm(prev => ({ ...prev, operatorHeight: form.operatorHeight - 1 }))
                  }
                />
                <span className='font-weight-normal form_counter_after_text'>ft.</span>
              </li>
              <li className='d-flex justify-content-between align-items-center w-50'>
                <div className='col-6 pl-0'>
                  <label className='m-0 text_no_wrap'>Operator Weight:</label>
                </div>
                <SmallCounter
                  count={form.operatorWeight}
                  handleIncreaseCount={() =>
                    setForm(prev => ({ ...prev, operatorWeight: form.operatorWeight + 1 }))
                  }
                  handleDecreaseCount={() =>
                    setForm(prev => ({ ...prev, operatorWeight: form.operatorWeight - 1 }))
                  }
                />
                <span className='font-weight-normal form_counter_after_text'>ft.</span>
              </li>
            </div>
            <li className='d-flex align-items-center'>
              <div className='col-3 pl-0'>
                <label className='m-0'>Operator Skin Color:</label>
              </div>
              <EditingSelect
                emptyValueClassName='empty_label_lightgray'
                name='operatorSkinColor'
                label='Choose Skin Color'
                onChange={handleChange}
                value={form.operatorSkinColor}
                items={optionsToList(skinColors)}
                useProperty='id'
                displayProperty='name'
                required={true}
                editing={true}
              />
            </li>
            <li className='d-flex align-items-center'>
              <div className='col-3 pl-0'>
                <label className='m-0'>Operator Ethnicity:</label>
              </div>
              <EditingSelect
                emptyValueClassName='empty_label_lightgray'
                name='operatorEthnicity'
                label='Choose Ethnicity'
                onChange={handleChange}
                value={form.operatorEthnicity}
                items={optionsToList(ethnicities)}
                useProperty='id'
                displayProperty='name'
                required={true}
                editing={true}
              />
            </li>
          </ul>
          <h6 className='font-weight-bold color-blue mt-5 mb-3'>3Dimensions™ Mammography System</h6>
          <ul className='p-0 mb-0'>
            <li className='d-flex align-items-center'>
              <div className='col-3 pl-0'>
                <label className='m-0'>Gantry Model:</label>
              </div>
              <EditingSelect
                hideDefault={true}
                emptyValueClassName='empty_label_lightgray'
                name='gantry'
                onChange={handleChange}
                value={form.gantry}
                items={optionsToList(gantryModels)}
                useProperty='id'
                displayProperty='name'
                required={true}
                editing={true}
              />
            </li>
            <li className='d-flex align-items-center hide-focus'>
              <div className='col-3 pl-0'>
                <label className='m-0'>Biopsy Usage:</label>
              </div>
              <div className='d-flex align-items-center form-radio-circle'>
                {optionsToList(biopsyUsage)
                  .reverse()
                  .map((item, i) => {
                    return (
                      <RadioBox
                        disabled={form.gantry === 'Standard Dimensions'}
                        className='mr-4'
                        name={item.name}
                        key={i}
                        label={item.name}
                        checked={Number(item.id) === Number(form.biopsyUsage)}
                        classNameLabel={'mb-0 fw-400'}
                        onChange={handleRadioChange.bind(null, 'biopsyUsage', item.id)}
                      />
                    );
                  })}
              </div>
            </li>
            <li className='d-flex align-items-center'>
              <div className='col-3 pl-0'>
                <label className='m-0'>Installed Paddle:</label>
              </div>
              <EditingSelect
                emptyValueClassName='empty_label_lightgray'
                label='Choose Paddle'
                name='installedPaddle'
                onChange={handleChange}
                value={form.installedPaddle}
                items={optionsToList(patientPaddles)}
                useProperty='id'
                displayProperty='name'
                required={true}
                editing={true}
              />
            </li>
            <li className='d-flex align-items-center'>
              <div className='col-3 pl-0'>
                <label className='m-0'>AWS Version:</label>
              </div>
              <EditingSelect
                emptyValueClassName='empty_label_lightgray'
                name='awsVersion'
                label='Choose Version'
                onChange={handleChange}
                value={form.awsVersion}
                items={optionsToList(awsVersions)}
                useProperty='id'
                displayProperty='name'
                required={true}
                editing={true}
              />
            </li>
          </ul>
          <h6 className=' font-weight-bold color-blue mt-5 mb-3'>Scanning</h6>
          <ul className='p-0 mb-0'>
            <li className='d-flex align-items-center'>
              <div className='col-3 pl-0'>
                <label className='m-0'>Patient Position:</label>
              </div>
              <EditingSelect
                emptyValueClassName='empty_label_lightgray'
                name='position'
                label='Choose Model'
                onChange={handleChange}
                value={form.position}
                items={optionsToList(patientPositions)}
                useProperty='id'
                displayProperty='name'
                required={true}
                editing={true}
              />
            </li>
            <li className='d-flex align-items-center'>
              <div className='col-3 pl-0'>
                <label className='m-0'>Injection Waiting Time:</label>
              </div>
              <EditingInput
                type='number'
                name='ciWaitTime'
                value={form.ciWaitTime}
                onChange={handleChange}
                className='p-0'
                placeholder='Time required to wait after Contrast injection'
                editing={true}
                access={true}
                min={0}
              />
              <span className='font-weight-light min-60 ml-2 text-right'>Seconds</span>
            </li>
            <li className='d-flex align-items-center'>
              <div className='col-3 pl-0'>
                <label className='m-0'>Effective Time:</label>
              </div>
              <EditingInput
                type='number'
                name='ciEffectiveScanTime'
                value={form.ciEffectiveScanTime}
                onChange={handleChange}
                className='p-0'
                placeholder='Effective time for scanning after injection'
                editing={true}
                access={true}
                min={0}
              />
              <span className='font-weight-light min-60 ml-2 text-right'>Seconds</span>
            </li>
            <li className='d-flex align-items-center'>
              <div className='col-3 pl-0'>
                <label className='m-0'>Scan Angle:</label>
              </div>
              <EditingSelect
                emptyValueClassName='empty_label_lightgray'
                name='scanAngle'
                label='Choose Scan Angle'
                onChange={handleChange}
                value={form.scanAngle}
                items={optionsToList(roomScanAngels)}
                useProperty='id'
                displayProperty='name'
                required={true}
                editing={true}
              />
            </li>
            <li className='d-flex align-items-center'>
              <div className='col-3 pl-0'>
                <label className='m-0'>Transition Effect:</label>
              </div>
              <EditingSelect
                emptyValueClassName='empty_label_lightgray'
                name='transitionEffect'
                label='Choose Effect'
                onChange={handleChange}
                value={form.transitionEffect}
                items={optionsToList(transitionEffects)}
                useProperty='id'
                displayProperty='name'
                required={true}
                editing={true}
              />
            </li>
          </ul>
          <h6 className=' font-weight-bold color-blue mt-5 mb-3'>
            Additional Equipment in the room
          </h6>
          <ul className='p-0 mb-0'>
            <li className='d-flex align-items-center hide-focus'>
              <div className='col-3 pl-0'>
                <label className='m-0'>Affirm Prone Biopsy:</label>
              </div>
              <div className='d-flex align-items-center form-radio-circle'>
                {optionsToList(affirmProneBiopsyUsage)
                  .reverse()
                  .map((item, i) => {
                    return (
                      <RadioBox
                        className='mr-4'
                        name={item.name + 'affirm'}
                        key={i}
                        label={item.name}
                        checked={Number(affirm.id) === Number(item.id)}
                        classNameLabel={'mb-0 fw-400'}
                        onChange={() => setAffirm(item)}
                      />
                    );
                  })}
              </div>
            </li>
            <li className='d-flex align-items-center hide-focus'>
              <div className='col-3 pl-0'>
                <label className='m-0'>Discovery DXA:</label>
              </div>
              <div className='d-flex align-items-center form-radio-circle'>
                {optionsToList(discoveryDXAUsage)
                  .reverse()
                  .map((item, i) => {
                    return (
                      <RadioBox
                        className='mr-4'
                        name={item.name + 'discoveryDXA'}
                        key={i}
                        label={item.name}
                        checked={Number(discoveryDXA.id) === Number(item.id)}
                        classNameLabel={'mb-0 fw-400'}
                        onChange={() => setDiscoveryDXA(item)}
                      />
                    );
                  })}
              </div>
            </li>
            <li className='d-flex align-items-center hide-focus'>
              <div className='col-3 pl-0'>
                <label className='m-0'>Fluoroscan Mini C-Arm:</label>
              </div>
              <div className='d-flex align-items-center form-radio-circle'>
                {optionsToList(fluoroscanMiniCArmUsage)
                  .reverse()
                  .map((item, i) => {
                    return (
                      <RadioBox
                        className='mr-4'
                        name={item.name + 'fluroscaMiniUse'}
                        key={i}
                        label={item.name}
                        checked={Number(fluroscanMiniUse.id) === Number(item.id)}
                        classNameLabel={'mb-0 fw-400'}
                        onChange={() => setFluroscanMiniUse(item)}
                      />
                    );
                  })}
              </div>
            </li>
            <li className='d-flex align-items-center hide-focus'>
              <div className='col-3 pl-0'>
                <label className='m-0'>Faxitron Path Specimen:</label>
              </div>
              <div className='d-flex align-items-center form-radio-circle'>
                {optionsToList(faxitronPathSpecimenUsage)
                  .reverse()
                  .map((item, i) => {
                    return (
                      <RadioBox
                        className='mr-4'
                        name={item.name + 'faxitronPath'}
                        key={i}
                        label={item.name}
                        checked={Number(faxitronPath.id) === Number(item.id)}
                        classNameLabel={'mb-0 fw-400'}
                        onChange={() => setFaxitronPath(item)}
                      />
                    );
                  })}
              </div>
            </li>
            <li className='d-flex align-items-center hide-focus'>
              <div className='col-3 pl-0'>
                <label className='m-0'>Faxitron™ Core Specimen:</label>
              </div>
              <div className='d-flex align-items-center form-radio-circle'>
                {optionsToList(faxitronCoreSpecimenUsage)
                  .reverse()
                  .map((item, i) => {
                    return (
                      <RadioBox
                        className='mr-4'
                        name={item.name + 'faxitronCore'}
                        key={i}
                        label={item.name}
                        checked={Number(faxitronCore.id) === Number(item.id)}
                        classNameLabel={'mb-0 fw-400'}
                        onChange={() => setFaxitronCore(item)}
                      />
                    );
                  })}
              </div>
            </li>
          </ul>
          <h6 className=' font-weight-bold color-blue mt-5 mb-3'>Environment</h6>
          <ul className='p-0 mb-0'>
            <div className='d-flex two_items_in_form_row'>
              <li className='d-flex justify-content-between align-items-center w-50'>
                <div className='col-6 pl-0'>
                  <label className='m-0'>Room Width:</label>
                </div>
                <SmallCounter
                  customClasses='pl-2'
                  count={form.patientHeight}
                  handleIncreaseCount={() =>
                    setForm(prev => ({ ...prev, patientHeight: form.patientHeight + 1 }))
                  }
                  handleDecreaseCount={() =>
                    setForm(prev => ({ ...prev, patientHeight: form.patientHeight - 1 }))
                  }
                />
                <span className='font-weight-normal form_counter_after_text'>ft.</span>
              </li>
              <li className='d-flex justify-content-between align-items-center w-50'>
                <div className='col-6 pl-0'>
                  <label className='m-0 text_no_wrap'>Room Height:</label>
                </div>
                <SmallCounter
                  count={form.patientWeight}
                  handleIncreaseCount={() =>
                    setForm(prev => ({ ...prev, patientWeight: form.patientWeight + 1 }))
                  }
                  handleDecreaseCount={() =>
                    setForm(prev => ({ ...prev, patientWeight: form.patientWeight - 1 }))
                  }
                />
                <span className='font-weight-normal form_counter_after_text'>ft.</span>
              </li>
            </div>
            <li className='d-flex align-items-center'>
              <div className='col-3 pl-0'>
                <label className='m-0'>Wall Types:</label>
              </div>
              <EditingSelect
                emptyValueClassName='empty_label_lightgray'
                name='wallTypes'
                label='Choose Type'
                onChange={handleChange}
                value={form.wallTypes}
                items={optionsToList(wallTypes)}
                useProperty='id'
                displayProperty='name'
                required={true}
                editing={true}
              />
            </li>
            <li className='d-flex align-items-center'>
              <div className='col-3 pl-0'>
                <label className='m-0'>Floor Type:</label>
              </div>
              <EditingSelect
                emptyValueClassName='empty_label_lightgray'
                name='floorType'
                label='Choose Type'
                onChange={handleChange}
                value={form.floorType}
                items={optionsToList(floorTypes)}
                useProperty='id'
                displayProperty='name'
                required={true}
                editing={true}
              />
            </li>
            <li className='d-flex align-items-center'>
              <div className='col-3 pl-0'>
                <label className='m-0'>Clock:</label>
              </div>
              <div className='switch_container'>
                <SwitchIOSLike
                  handleChange={() => handleSwitch('isClockActive')}
                  checkedState={form.isClockActive}
                />
              </div>
              <EditingSelect
                disabled={!form.isClockActive}
                emptyValueClassName='empty_label_lightgray'
                name='clockType'
                label='Choose Type'
                onChange={handleChange}
                value={form.clockType}
                items={optionsToList(clockTypes)} // need to change for clock options
                useProperty='id'
                displayProperty='name'
                required={true}
                editing={true}
              />
            </li>
            <li className='d-flex align-items-center hide-focus'>
              <div className='col-3 pl-0'>
                <label className='m-0'>Decors:</label>
              </div>
              <div className='d-flex align-items-center form-radio-circle'>
                {optionsToList(decors)
                  .reverse()
                  .map((item, i) => {
                    return (
                      <RadioBox
                        className='mr-4 rooms_custom_radio_block'
                        name={item.name}
                        key={i}
                        label={item.name}
                        checked={Number(item.id) === Number(form.decor1)}
                        classNameLabel={'mb-0 fw-400'}
                        onChange={handleRadioChange.bind(null, 'decor1', item.id)}
                      />
                    );
                  })}
              </div>
            </li>
          </ul>
          <h6 className=' font-weight-bold color-blue mt-5 mb-3'>AR App</h6>
          <ul className='p-0 mb-0'>
            <li className='d-flex'>
              <div className='col-3 pl-0 pt-1'>
                <label className='m-0'>Patient Model:</label>
              </div>
              <div className='w-100'>
                <div className='d-flex mb-3 align-items-center'>
                  <div className='switch_container'>
                    <SwitchIOSLike
                      handleChange={() => handleSwitch('arAppDiffFromWeb')}
                      checkedState={form.arAppDiffFromWeb}
                    />
                  </div>
                  <span className='font-weight-normal'>Different from 360 Web</span>
                </div>
                <EditingSelect
                  emptyValueClassName='empty_label_lightgray'
                  name='arAppModel'
                  label='Choose Patient Model'
                  onChange={handleChange}
                  value={!form.arAppDiffFromWeb ? form.model : form.arAppModel}
                  items={optionsToList(patientModels)}
                  useProperty='id'
                  displayProperty='name'
                  required={true}
                  disabled={!form.arAppDiffFromWeb}
                  editing={true}
                />
              </div>
            </li>
            <li className='d-flex align-items-center'>
              <div className='col-3 pl-0'>
                <label className='m-0'>Marker:</label>
              </div>
              <EditingSelect
                emptyValueClassName='empty_label_lightgray'
                name='arAppMarker'
                label='Choose Marker'
                onChange={handleChange}
                value={form.arAppMarker}
                items={optionsToList(appMarkers)}
                useProperty='id'
                displayProperty='name'
                required={true}
                editing={true}
              />
            </li>
            <li className='d-flex align-items-center'>
              <div className='col-3 pl-0'>
                <label className='m-0'>Number of Masses:</label>
              </div>
              <SmallCounter count={arAppNumOfMass} setCount={setArAppNumOfMass} />
            </li>
            <li className='d-flex align-items-center'>
              <div className='col-3 pl-0'>
                <label className='m-0'>Pathology:</label>
              </div>
              <EditingSelect
                emptyValueClassName='empty_label_lightgray'
                name='pathology'
                label='Choose Pathology'
                onChange={handleChange}
                value={form.pathology}
                items={optionsToList(pathologies)}
                useProperty='id'
                displayProperty='name'
                required={true}
                editing={true}
              />
            </li>
          </ul>
          <div className='d-flex justify-content-end mt-4 border-top pt-4'>
            <Button
              className='btn btn-blue ml-2 px-4 fz-12 font-weight-bold'
              type='submit'
              disabled={fetch}
            >
              {id ? 'Update' : 'Save'}
            </Button>
          </div>
        </form>
        <div className='mt-100 col-3 d-flex flex-column align-items-center'>
          <h4 className='section_title'>Patient Model</h4>
          {data?.modelImageUrl || form.model ? (
            <img
              className='patient_model_img'
              src={
                form?.model ? 'https://content.nodemo.site/content/pm1.png' : data?.modelImageUrl
              }
              alt='model'
            />
          ) : (
            <IconWomanModel width='100%' height='500' color='#b06334' />
          )}
          {(data || form.model) && (
            <div className='model-info'>
              <div className='d-flex align-items-center justify-content-between'>
                <span>Age</span>
                <b>{data?.age || 35}</b>
              </div>
              <div className='d-flex align-items-center justify-content-between'>
                <span>Breast Cup Size</span>
                <b>{breastSizes[(form?.breastSize)] || data?.breastSize || 'B'}</b>
              </div>
              <div className='d-flex align-items-center justify-content-between'>
                <span>Race</span>
                <b>Caucasus</b>
              </div>
              <div className='d-flex align-items-center justify-content-between'>
                <span>Johnny</span>
                <b>{form?.johnny || data?.johnny || 'Robe'}</b>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RoomsNew;
