import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import attachIcon from 'assets/attach.svg';
import EditingInput from 'shared/components/editingForms/EditingInput';
import EditingTextArea from 'shared/components/editingForms/EditingTextArea';
import { getError } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import Select from 'react-select';
import EditingCheckBox from 'shared/components/editingForms/EditingCheckBox';
import TagsSelect from 'shared/components/TagsSelect';
import { courseTags } from 'configs';
import ReactTooltip from 'react-tooltip';
import { IconWarning } from 'shared/components/Icons';
import { hasAccess } from 'utils/permissionHelper';

const CourseGeneralStep = ({
  form,
  setForm,
  data,
  setData,
  isRequired,
  tempLocalActiveLessons,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [franch, setFranch] = useState();

  const getFranchises = async () => {
    try {
      const { data } = await Api.get('/wizard/franchises');
      setFranch(data);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const handleChange = ({ target }) => {
    const { name, value, checked, type } = target;
    const newForm = { ...form };

    if (
      (name === 'isCertificateAvailable' || name === 'isQuizAvailable') &&
      checked &&
      form.isOpened
    ) {
      newForm['isOpened'] = false;
      newForm.isPubliclyAccessible = 0;
    }
    if ((name === 'isPubliclyAvailable' || name === 'isOpened') && !checked) {
      newForm.isPubliclyAccessible = 0;
    }
    if (name === 'isMarketingMaterial' && checked) {
      const onlyOneMarketingLessonArr = data?.activeLessons
        ?.filter(lesson => lesson?.isMarketingMaterial)
        .slice(0, 1);
      setData({
        ...data,
        activeLessons: onlyOneMarketingLessonArr,
      });
      newForm.isCertificateAvailable = 0;
      newForm.isPubliclyAccessible = 0;
      newForm.isPubliclyAvailable = 0;
      newForm.isOpened = 1;
    }
    if (name === 'isMarketingMaterial' && !checked && tempLocalActiveLessons?.length) {
      setData({ ...data, activeLessons: [...data?.activeLessons, ...tempLocalActiveLessons] });
    }

    const val = type === 'checkbox' ? (checked ? 1 : 0) : value;
    newForm[name] = val;
    setForm(newForm);
  };

  const onImageUpload = async e => {
    try {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append('file', file);
      formData.append('isdraft', 'true');
      const res = await Api.post('/upload', formData);
      setData({ ...data, lessonImage: res.data.data });
      setForm({ ...form, uploadImageId: res.data.data.id });
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const lessonImg = data.lessonImage && data.lessonImage.url;

  const customStyles = {
    placeholder: (provided, state) => ({
      ...provided,
      fontWeight: 300,
      color: '#dddddd',
    }),
  };

  useEffect(() => {
    getFranchises();
    //eslint-disable-next-line
  }, []);

  return (
    <div className='px-2'>
      <h6 className='color-blue mb-3'>Course General Settings</h6>
      <div className='col-9 p-0 user-creation-form'>
        <ul className='p-0 mb-0'>
          <li className='d-flex align-items-center'>
            <div className='col-4 pl-0'>
              <label className='m-0'>Course Name*:</label>
            </div>
            <EditingInput
              type='text'
              name='name'
              value={form.name}
              onChange={handleChange}
              className='p-0'
              placeholder='Enter Name'
              required={isRequired}
              editing={true}
              maxLength='180'
            />
          </li>
          <li className='d-flex align-items-center'>
            <div className='col-4 pl-0'>
              <label className='m-0'>Course Image:</label>
            </div>
            <div className='upload-file-form w-100 overflow-hidden'>
              <input
                id='file'
                name='file'
                type='file'
                className='flex-fill hide'
                onChange={onImageUpload}
              />
              <label
                htmlFor='file'
                className={`d-flex justify-content-between align-items-center text-nowrap ${
                  lessonImg ? 'bg-transparent pl-0' : ''
                }`}
              >
                {lessonImg && <img height='40' className='mr-2' src={lessonImg} alt='img' />}
                {!lessonImg && 'Upload Image'}
                <div>
                  {lessonImg && 'Change Image'}
                  <img src={attachIcon} className='ml-2' alt='icon' />
                </div>
              </label>
            </div>
          </li>
          <li className='d-flex align-items-center'>
            <div className='col-4 pl-0'>
              <label className='m-0'>Course Category:</label>
            </div>
            <Select
              styles={customStyles}
              className='multi-select'
              classNamePrefix='multi-select-pre'
              getOptionLabel={o => o.name}
              getOptionValue={o => o.id}
              value={data.franchises}
              onChange={franchises => setData({ ...data, franchises })}
              isMulti
              options={franch}
            />
          </li>
          <li className='d-flex align-items-center'>
            <div className='col-4 pl-0'>
              <label className='m-0'>Course Duration*:</label>
            </div>
            <EditingInput
              type='number'
              name='duration'
              value={form.duration}
              onChange={handleChange}
              className='p-0'
              placeholder='Duration in hours'
              required={isRequired}
              editing={true}
              access={true}
            />
          </li>
          <li className='d-flex'>
            <div className='col-4 pl-0 pt-1'>
              <label className='m-0'>Short Description*:</label>
            </div>
            <EditingTextArea
              type='text'
              name='description'
              value={form.description}
              onChange={handleChange}
              className='p-0 is-large'
              placeholder='Enter Description'
              required={isRequired}
              editing={true}
              access={true}
              maxLength='1024'
            />
          </li>
          <li className='d-flex align-items-center'>
            <div className='col-4 pl-0'>
              <label className='m-0'>Course Nickname:</label>
            </div>
            <EditingInput
              type='text'
              name='nickName'
              value={form.nickName}
              onChange={handleChange}
              className='p-0'
              placeholder='Enter Nickname'
              editing={true}
              access={true}
              maxLength='255'
            />
          </li>
          {hasAccess('marketing_course_create') && (
            <li className='d-flex align-items-center'>
              <div className='col-4 pl-0'>
                <label className='m-0'>Marketing Material:</label>
              </div>
              <EditingCheckBox
                name='isMarketingMaterial'
                value={form.isMarketingMaterial}
                onChange={handleChange}
                disabled={!hasAccess('education_course_create')}
                className='p-0'
                checked={form.isMarketingMaterial}
                editing={true}
              />
            </li>
          )}
          <li className='d-flex align-items-center'>
            <div className='col-4 pl-0'>
              <label className='m-0'>Quiz Enable:</label>
            </div>
            <EditingCheckBox
              name='isQuizAvailable'
              value={form.isQuizAvailable}
              onChange={handleChange}
              className='p-0'
              checked={form.isQuizAvailable}
              disabled={!data.quiz}
              editing={true}
            />
          </li>
          <li className='d-flex align-items-center'>
            <div className='col-4 pl-0'>
              <label className='m-0'>Evaluation Available:</label>
            </div>
            <EditingCheckBox
              name='isEvaluationAvailable'
              value={form.isEvaluationAvailable}
              onChange={handleChange}
              className='p-0'
              checked={form.isEvaluationAvailable}
              editing={true}
            />
          </li>
          <li className='d-flex align-items-center'>
            <div className='col-4 pl-0'>
              <label className='m-0'>Certificate Provisioning:</label>
            </div>
            <EditingCheckBox
              name='isCertificateAvailable'
              value={form.isCertificateAvailable}
              onChange={handleChange}
              disabled={form.isMarketingMaterial}
              className='p-0'
              checked={form.isCertificateAvailable}
              editing={true}
            />
          </li>
          <li className='list-default'>
            <div className='d-flex align-items-center'>
              <div className='col-4 pl-0'>
                <label className='m-0'>Unordered Course:</label>
              </div>
              <EditingCheckBox
                name='isOpened'
                value={form.isOpened}
                onChange={handleChange}
                className='p-0'
                checked={form.isOpened}
                editing={true}
                disabled={
                  form.isCertificateAvailable || form.isQuizAvailable || form.isMarketingMaterial
                }
              />
            </div>
            <p className='small m-0 text-secondary mt-2'>
              The learner is not required to view all lessons in any particular order. This type of
              course has no certificate.
            </p>
          </li>
          <li className='d-flex align-items-center'>
            <div className='col-4 pl-0'>
              <label className='m-0'>Publicly Available:</label>
            </div>
            <EditingCheckBox
              name='isPubliclyAvailable'
              value={form.isPubliclyAvailable}
              onChange={handleChange}
              className='p-0'
              checked={form.isPubliclyAvailable}
              editing={true}
              disabled={form.isMarketingMaterial}
            />
          </li>
          <li className='d-flex align-items-center'>
            <div className='col-4 pl-0'>
              <label className='m-0'>Publicly Accessible:</label>
            </div>
            <EditingCheckBox
              name='isPubliclyAccessible'
              value={form.isPubliclyAccessible}
              onChange={handleChange}
              className='p-0'
              checked={form.isPubliclyAccessible}
              editing={true}
              disabled={!form.isPubliclyAvailable || !form.isOpened || form.isMarketingMaterial}
            />
          </li>
          <li className='d-flex align-items-center'>
            <div className='col-4 pl-0'>
              <div className='m-0 d-flex align-items-center'>
                Learner Tags:
                <p
                  data-tip='These tags are seen and can <br /> be used by learners for <br /> filtering courses'
                  className='mb-0 ml-2 d-flex flex-column align-items-center'
                >
                  <IconWarning width='12' height='12' color='#131F6B' />
                </p>
              </div>
            </div>
            <TagsSelect
              menuPlacement='top'
              value={data.learnerTags}
              onChange={tags => setData({ ...data, learnerTags: tags })}
              placeholder='Add a New Tag'
              type={courseTags.learner}
            />
          </li>
          <li className='d-flex align-items-center'>
            <div className='col-4 pl-0'>
              <div className='m-0 d-flex align-items-center'>
                Admin Tags:
                <p
                  data-tip='These tags are addition to <br /> Learner Tags and are visible <br /> and can be used only by <br /> admins'
                  className='mb-0 ml-2 d-flex flex-column align-items-center'
                >
                  <IconWarning width='12' height='12' color='#131F6B' />
                </p>
              </div>
            </div>
            <TagsSelect
              menuPlacement='top'
              value={data.adminTags}
              onChange={tags => setData({ ...data, adminTags: tags })}
              placeholder='Add a New Tag'
              type={courseTags.admin}
            />
          </li>
        </ul>
      </div>
      <ReactTooltip effect='solid' type='light' multiline={true} backgroundColor='#FFF' />
    </div>
  );
};

export default CourseGeneralStep;
