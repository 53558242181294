import React, { useCallback, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { components } from 'react-select';
import { useSnackbar } from 'notistack';

import debounce, { getError, getImageUrl } from 'utils/appHelpers';
import { Api } from 'utils/connectors';

export const UserOption = props => {
  const { user } = props;
  const mainUser = user.user || user;
  const name = mainUser.firstName
    ? `${mainUser.firstName} ${mainUser.lastName}`
    : mainUser.fullName;
  return (
    <div className='d-flex align-items-center'>
      <img
        width='36'
        height='36'
        src={getImageUrl(mainUser.imageUrl)}
        alt={mainUser.firstname}
        className='user-img mr-2'
      />
      <div>
        <b>{name}</b>
        <p className='mb-0 font-weight-normal'>{mainUser.email}</p>
      </div>
    </div>
  );
};

const MyOption = props => {
  if (props.activeUsers.find(i => i.id === props.data.id)) return null;
  return (
    <div className='d-flex align-items-center z-index-100'>
      <components.Option {...props}>
        <UserOption user={props.data} />
      </components.Option>
    </div>
  );
};

const UsersSelect = props => {
  const {
    name,
    onChange,
    placeholder,
    activeUsers = [],
    isKOLUsers = false,
    customClasses,
    inputValue,
    handleInputChange = () => {},
    isLearners,
    isAdmins,
    setLastOptions = () => {},
    controlStyles = {},
    placeholderStyles = {},
    isHiddenRightIcons,
    permissions,
    permissionAccessLevel,
  } = props;
  const { id } = useParams();
  const selectedUsers = useRef(activeUsers);
  const kolRole = useRef();

  const noOptionsMessage = isLearners
    ? () => (inputValue.length < 3 ? 'Type at least 3 letters' : 'No matching')
    : undefined;

  const { enqueueSnackbar } = useSnackbar();

  const getRoles = async () => {
    try {
      const params = { page: 1, limit: 1000, excludeSA: true };
      const { data } = await Api.get('/admin/roles', { params });
      const kol = data.data.results.find(i => i.title === 'KOL');
      if (kol) kolRole.current = kol;
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const getUsers = async (search, callback) => {
    try {
      let endpoint;
      let data;
      if (isLearners) {
        if (search.length > 2) {
          endpoint = '/entity/assignees';
          const res = await Api.get(endpoint, {
            params: { limit: 1000, search, entityId: id },
          });
          data = res?.data;
        }
      } else if (isAdmins) {
        endpoint = '/admin/users-with-permission';
        const params = {
          permissions: permissions.reduce((acc, perm, idx) => acc + `${idx ? ',' : ''}${perm}`, ''),
          accessLevel: permissionAccessLevel,
        };
        const res = await Api.get(endpoint, {
          params,
        });
        data = res?.data;
      } else {
        if (!kolRole.current) {
          await getRoles();
        }
        endpoint = isKOLUsers
          ? `/admin/role/${kolRole.current.id}/assigned-users`
          : '/admin/getusers';
        const res = await Api.get(endpoint, {
          params: { limit: 10, fetch_all: true, page: 1, search },
        });
        data = res?.data;
      }
      const users = Array.isArray(data?.data) ? data?.data : data?.data?.results;
      const newUsers = users?.filter(
        user => !selectedUsers.current?.find(i => i?.user?.id === user?.id),
      );
      setLastOptions(newUsers);
      callback(newUsers);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
      callback([]);
    }
  };

  const debounceGetUsers = useCallback(
    debounce(async (search, callback) => {
      getUsers(search, callback);
    }, 500),
    [],
  );

  const addSharedUser = user => {
    if (activeUsers.find(i => i?.id === user?.id)) return;
    onChange(user);
  };

  const Option = props => {
    return <MyOption activeUsers={activeUsers} {...props} />;
  };

  const defaultComponents = { Option };

  const hideRightIconsComponents = {
    DropdownIndicator: () => null,
    IndicatorSeparator: () => null,
  };

  useEffect(() => {
    selectedUsers.current = activeUsers;
  }, [activeUsers]);

  return (
    <AsyncSelect
      className={`user-select ${customClasses}`}
      components={
        isHiddenRightIcons
          ? { ...defaultComponents, ...hideRightIconsComponents }
          : defaultComponents
      }
      onChange={addSharedUser}
      openMenuOnFocus
      onInputChange={(val, eObject) => handleInputChange(val, eObject, name)}
      placeholder={!inputValue && placeholder}
      inputValue={inputValue}
      cacheOptions
      defaultOptions={true}
      noOptionsMessage={noOptionsMessage}
      value={[]}
      loadOptions={(search, callback) => {
        debounceGetUsers(search, callback);
      }}
      styles={{
        // Fixes the overlapping problem of the component
        menu: provided => ({ ...provided, zIndex: 9999 }),
        control: provided => ({ ...provided, ...controlStyles }),
        placeholder: provided => ({ ...provided, ...placeholderStyles }),
      }}
      blurInputOnSelect={true}
    />
  );
};

export default UsersSelect;
