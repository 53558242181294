/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';

const EntityItem = ({ entity, isActive, type = 'mid', handleClick = () => {}, noHoverState }) => {
  return (
    <div
      onClick={handleClick}
      className={`entity-item ${noHoverState && 'no-hover-state'} ${type} ${isActive && 'active'}`}
    >
      {entity.imageUrl ? (
        <img className='banner' alt={entity.name} src={entity.imageUrl} />
      ) : (
        <div className='no-img-banner'>
          <h6 className='first-letter'>{entity?.name?.[0]}</h6>
          <p className='name'>{entity.name}</p>
        </div>
      )}
    </div>
  );
};

export default EntityItem;
