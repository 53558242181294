import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { Api } from 'utils/connectors';
import UserHeader from './components/UserHeader';
import UserInfo from './components/UserInfo';
import Loading from 'shared/components/Loading';
import UserStatistics from './components/UserStatistics';
import { getError } from 'utils/appHelpers';
import { hasAccess } from 'utils/permissionHelper';

const generateFeedBackData = data => {
  if (!data || !data.length) return [];
  const result = {};
  data.forEach(item => {
    const { feedback, course } = item.courseFeedback;
    if (result[course.id]) {
      result[course.id].feedbacks.push({ ...feedback, value: item.value });
    } else {
      result[course.id] = course;
      result[course.id].feedbacks = [{ ...feedback, value: item.value }];
    }
  });
  return Object.values(result);
};

const UserView = ({ match, history }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [user, setUser] = useState();
  const isInvited = match.params.type === 'invited';
  const isEventView = hasAccess('entity_manager');

  const getUserData = async () => {
    try {
      const { id } = match.params;
      const path = isInvited ? '/admin/getinvitationbyid' : '/auth/getuserbyid';
      const res = await Api.get(`${path}/${id}`, {});
      if (!isInvited && !isEventView) {
        if (hasAccess('certificates_list_view')) {
          const certificates = await Api.get(`courses/getusercertificates/${id}`);
          res.data.data.certificates = certificates.data.data;
        }
        const feedbacks = await Api.get(`/feedback/getuserfeedbacks/${id}`);
        res.data.data.feedbacks = generateFeedBackData(feedbacks.data.data);
      }
      setUser(res.data.data);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  useEffect(() => {
    getUserData();
    //eslint-disable-next-line
  }, [match.params.id]);

  if (!user) return <Loading className='mt-5' />;

  return (
    <div>
      <UserHeader user={user} />
      <div className='col-12 pr-0 py-4 row has-header'>
        <div className='col-8'>
          <UserInfo user={user} getUserData={getUserData} isInvited={isInvited} />
        </div>
        {!isInvited && (
          <div className='col-4'>
            <UserStatistics user={user} />
          </div>
        )}
      </div>
    </div>
  );
};

export default UserView;
