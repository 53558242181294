/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useSnackbar } from 'notistack';

import { bindDuration, getError } from 'utils/appHelpers';
import { eventTypes, realizations } from 'app/Main/configs';
import { hasAccess } from 'utils/permissionHelper';
import { Api } from 'utils/connectors';
import EventStatistics from '../../Events/components/EventStatistics';
import hologicLogo from 'assets/ACE-logo-blue.svg';
import VirtualCopyRow from 'shared/components/VirtualCopyRow';

const PublishRow = ({ label, data, onClick }) =>
  data || data === 0 ? (
    <p
      className={`d-flex justify-content-between mb-2 ${
        onClick ? 'link-color pointer text-underline' : ''
      }`}
      onClick={onClick}
    >
      <span>{label}</span>
      <span>{label === 'Duration' ? bindDuration(data) : data}</span>
    </p>
  ) : null;

const PublishPreview = ({ data, getData }) => {
  const [statsModale, setStatsModale] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [fetching, setFetching] = useState(false);
  const type = eventTypes.find(i => i.id === data.eventType);
  const rel = realizations.find(i => i.id === data.realization);
  const isPublished = data.status === 1;
  const publishUrl = data?.webinarUrl;

  const toggleEvent = async () => {
    try {
      setFetching(true);
      const endpoint = !isPublished ? '/events/publish' : '/events/unpublish';
      await Api.get(`${endpoint}/${data.id}`);
      getData();
      enqueueSnackbar('Successfully done', { variant: 'success' });
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetching(false);
    }
  };

  const onShowStatistics = () => {
    setStatsModale(data.id);
  };

  const onClickPreview = () => {
    window.open(publishUrl, '_blank');
  };

  const onStatsClose = () => {
    setStatsModale(false);
  };

  const isWebinar = Number(data.eventType) === 0;

  return (
    <>
      <div className='publish-block p-3 mb-2'>
        <img src={data.organizerLogoUrl || hologicLogo} alt='preview' />
        {!!publishUrl && (
          <div className='my-3'>
            <VirtualCopyRow url={publishUrl} title={data?.name} />
          </div>
        )}
        <PublishRow
          label='Dates'
          data={`${moment(data.startDate).format('MMM DD')} - ${moment(data.endDate).format(
            'MMM DD',
          )}`}
        />
        <PublishRow label='Type' data={type ? type.name : '-'} />
        <PublishRow label='Realisation' data={rel ? rel.name : '-'} />
        <PublishRow label='Organiser' data={data.organizer} />
        <PublishRow label='Registered User' data={'-'} />
        <PublishRow label='Status' data={data.status ? 'Published' : 'Unpublished'} />
      </div>
      {!!isWebinar && (
        <div className='d-flex align-items-center justify-content-between mb-2 gap_8'>
          <Link to={`/events/${data.id}/edit`} className={`btn btn-sm btn-blue w-100`}>
            Edit
          </Link>
          <button className={`btn btn-sm btn-blue w-100`} onClick={onClickPreview}>
            Preview
          </button>
          <button className={`btn btn-sm btn-blue w-100`} onClick={onShowStatistics}>
            Statistics
          </button>
        </div>
      )}
      {hasAccess('event_create') && (
        <p>
          <button className={`btn btn-sm btn-blue w-100`} onClick={toggleEvent} disabled={fetching}>
            {isPublished ? 'Unpublish' : 'Publish'}
          </button>
        </p>
      )}
      {statsModale && <EventStatistics onClose={onStatsClose} eventId={statsModale} />}
    </>
  );
};

export default PublishPreview;
