import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { getError, imageUploadProgress } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import IconVideo from 'assets/wizard/select_video.svg';
import IconPdf from 'assets/wizard/select_pdf.svg';
import IconActivePdf from 'assets/wizard/active_pdf.svg';
import IconImage from 'assets/wizard/select_image.svg';
import IconCases from 'assets/wizard/select_cases.svg';
import { IconTick } from 'shared/components/Icons';
import UploadProgress from 'shared/components/UploadProgress';

const LessonContentFileStep = ({ form, setForm, data, setData, isInUse }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [uploadProgress, setUploadProgress] = useState();

  const onFileUpload = async e => {
    const config = {
      onUploadProgress: imageUploadProgress.bind(null, setUploadProgress),
    };

    try {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append('file', file);
      formData.append('isdraft', 'true');
      const res = await Api.post('/upload', formData, config);
      setData({ ...data, contentFile: res.data.data });
      const updateForm = { uploadContentId: res.data.data.id, uploadContentUrl: res.data.data.url };
      if (!!res && form.contentType === 1) {
        updateForm.duration = res.data.data.duration;
      }
      setForm(form => ({ ...form, ...updateForm }));
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setUploadProgress();
    }
  };

  const uploadTypes = {
    1: {
      title: 'Video File Settings',
      placeholder: 'Upload Video',
      uploadPlaceholder: 'Uploading Video',
      icon: IconVideo,
      accept: 'video/mp4,video/x-m4v,video/*',
      onChange: onFileUpload,
      content: data => {
        return (
          <video key={data.url} width='283' className='mb-2' controls>
            <source src={data.url} type='video/mp4' />
          </video>
        );
      },
    },
    2: {
      title: 'PDF File Settings',
      placeholder: 'Upload PDF',
      uploadPlaceholder: 'Uploading PDF',
      icon: IconPdf,
      accept: 'application/pdf,application/vnd.ms-excel',
      onChange: onFileUpload,
      content: data => {
        const urlFileName = data.url.substring(data.url.lastIndexOf('/') + 1);
        const fileName = data.fileName.includes('http') ? urlFileName : data.fileName;
        return (
          <div className='text-center'>
            <img className='mb-0' src={IconActivePdf} alt='icon' />
            <p className='text-center mt--20'>{fileName}</p>
          </div>
        );
      },
    },
    3: {
      title: 'Imagery Settings',
      placeholder: 'Select Cases',
      accept: 'image/png, image/jpeg, image/bmp, image/gif',
      icon: IconCases,
      onChange: () => {},
    },
    4: {
      title: 'Image File Settings',
      placeholder: 'Upload Image',
      uploadPlaceholder: 'Uploading Image',
      icon: IconImage,
      accept: 'image/*',
      onChange: onFileUpload,
      content: data => {
        return <img className='mb-2 text-center' width='283' src={data.url} alt='lesson img' />;
      },
    },
  };

  const activeType = uploadTypes[form.contentType];
  const media = data.contentFile;

  return (
    <div className='px-2'>
      <h6 className='color-blue mb-3'>{activeType.title}</h6>
      <label className={`col-7 upload-wizard-block ${isInUse ? '' : 'pointer'}`} htmlFor='file'>
        <input
          id='file'
          name='file'
          type='file'
          className='flex-fill hide'
          accept={activeType.accept}
          disabled={isInUse}
          onChange={activeType.onChange}
        />
        {media && (
          <div className='success-uploaded'>
            <IconTick color='#7ad181' />
          </div>
        )}
        <div className='d-flex flex-column align-items-center'>
          {media && activeType.content ? (
            activeType.content(media)
          ) : (
            <img src={activeType.icon} alt='icon' />
          )}
          {!!uploadProgress && <UploadProgress progress={uploadProgress} />}
          {!!uploadProgress && activeType.uploadPlaceholder}
          {!media && !uploadProgress && activeType.placeholder}
        </div>
      </label>
    </div>
  );
};

export default LessonContentFileStep;
