import React from 'react';
import EditingInput from 'shared/components/editingForms/EditingInput';
import EditingTextArea from 'shared/components/editingForms/EditingTextArea';
import EditingSelect from 'shared/components/editingForms/EditingSelect';
import { IconBin } from 'shared/components/Icons';
import { getDicomsSelects } from '../../LessonsNew/utils';
import { getEmptyDescObj, getEmptyStepObj, stepTypes } from '../utils';
import { getBase64 } from 'utils/appHelpers';

const CreationForm = ({
  onSubmitUser,
  fetch,
  form,
  setForm,
  caseData,
  onSaveWithDraft,
  onRemoveBiards,
}) => {
  const handleSubmit = e => {
    e.preventDefault();
    onSubmitUser(form);
  };

  const handleSectionChange = (index, { target }) => {
    const temp = { ...form };
    const { name, value } = target;
    temp.answers.sections[index][name] = value;
    setForm(temp);
  };

  const onAddSections = () => {
    const temp = { ...form };
    temp.answers.sections.push(getEmptyDescObj());
    setForm(temp);
  };

  const removeSections = index => {
    const temp = { ...form };
    temp.answers.sections.splice(index, 1);
    setForm(temp);
  };

  const handleStepChange = (index, { target }) => {
    const temp = { ...form };
    const { name, value } = target;
    temp.answers.steps[index][name] = value;
    setForm(temp);
  };

  const onAddSteps = () => {
    const temp = { ...form };
    temp.answers.steps.push(getEmptyStepObj());
    setForm(temp);
  };

  const removeSteps = index => {
    const temp = { ...form };
    temp.answers.steps.splice(index, 1);
    setForm(temp);
  };

  const handleImageSelect = (index, file) => {
    getBase64(file, result => {
      const temp = { ...form };
      temp.answers.steps[index].image = result;
      temp.answers.steps[index].filename = file.name;
      setForm(temp);
    });
  };

  const handleFrameChange = (index, type, e) => {
    const temp = { ...form };
    const step = temp.answers.steps[index];
    let { name, value } = e.target;
    step[type] = { ...step[type], [name]: value };
    setForm(temp);
  };

  if (!form) return null;

  return (
    <form onSubmit={handleSubmit} className='col-8 user-creation-form'>
      <div>
        {form.answers.sections &&
          form.answers.sections.map((item, index) => {
            const isLast = index + 1 === form.answers.sections.length;
            return (
              <div className='col-12 p-0 user-creation-form mb-4' key={index}>
                <ul className='p-0 mb-0'>
                  <li className='d-flex align-items-center'>
                    <div className='col-4 pl-0'>
                      <label className='m-0'>Answer Title {index + 1}:</label>
                    </div>
                    <EditingInput
                      type='text'
                      name='title'
                      value={item.title}
                      onChange={handleSectionChange.bind(null, index)}
                      className='p-0'
                      placeholder='Enter Title'
                      required
                      editing={true}
                    />
                  </li>
                  <li className='d-flex'>
                    <div className='col-4 pl-0 pt-1'>
                      <label className='m-0'>Answer Description {index + 1}:</label>
                    </div>
                    <EditingTextArea
                      name='descriptions'
                      value={item.descriptions}
                      onChange={handleSectionChange.bind(null, index)}
                      className='p-0 is-large'
                      placeholder='Enter Description'
                      required
                      editing={true}
                    />
                  </li>
                </ul>
                <div className='mt-3 d-flex align-items-center justify-content-between'>
                  <div>
                    {form.answers.sections.length > 1 && (
                      <button
                        type='button'
                        className='btn btn-sm pl-0 text-danger d-flex align-items-center'
                        onClick={removeSections.bind(null, index)}
                      >
                        <IconBin className={'mr-2'} />
                        Remove
                      </button>
                    )}
                  </div>
                  {isLast && (
                    <button
                      type='button'
                      className='btn btn-blue add-new-frames fz-14'
                      onClick={onAddSections}
                    >
                      New Answer +
                    </button>
                  )}
                </div>
              </div>
            );
          })}
        {form.answers.steps &&
          form.answers.steps.map((item, index) => {
            const isLast = index + 1 === form.answers.steps.length;
            const leftData = getDicomsSelects(caseData.dicoms, item.caseLeftFrame);
            const rightData = getDicomsSelects(caseData.dicoms, item.caseRightFrame);
            return (
              <div className='col-12 p-0 user-creation-form mb-4' key={index}>
                <p className='text-blue font-weight-bold mb-2'>Substep {index + 1}</p>
                <ul className='p-0 mb-0'>
                  <li className='d-flex align-items-center'>
                    <div className='col-3 pl-0'>
                      <label className='m-0'>Answer Type:</label>
                    </div>
                    <EditingSelect
                      name='stepType'
                      onChange={handleStepChange.bind(null, index)}
                      value={item.stepType}
                      items={stepTypes}
                      useProperty='value'
                      displayProperty='name'
                      required={true}
                      editing={true}
                      hideDefault={true}
                    />
                  </li>
                  {item.stepType === 'image' && (
                    <li className='d-flex align-items-center'>
                      <div className='col-3 pl-0'>
                        <label className='m-0'>Image:</label>
                      </div>
                      <div className='flex-fill d-flex align-items-center justify-content-between'>
                        <span className='text-secondary font-weight-light pl-2'>
                          {item.image || item.imageUrl ? (
                            <img
                              height='40'
                              className='mr-2'
                              src={item.image || item.imageUrl}
                              alt='img'
                            />
                          ) : (
                            'Not Selected'
                          )}
                        </span>
                        <input
                          id={`file${index}`}
                          name={`file${index}`}
                          type='file'
                          className='flex-fill hide'
                          onChange={e => handleImageSelect(index, e.target.files[0])}
                        />
                        <label
                          htmlFor={`file${index}`}
                          className='btn-sm px-4 btn btn-white m-0 pointer'
                        >
                          Choose Image
                        </label>
                      </div>
                    </li>
                  )}
                  {item.stepType === 'case' && (
                    <div className='d-flex'>
                      <div className='col p-0'>
                        <p className='mb-1 mt-2 fw-600'>Frame 1*:</p>
                        <li className='d-flex align-items-center'>
                          <div className='col-3 pl-0'>
                            <label className='m-0'>Type*:</label>
                          </div>
                          <EditingSelect
                            name='imageType'
                            onChange={handleFrameChange.bind(null, index, 'caseLeftFrame')}
                            value={item.caseLeftFrame.imageType || ''}
                            items={leftData.types || []}
                            useProperty='value'
                            displayProperty='name'
                            required={true}
                            editing={true}
                          />
                        </li>
                        <li className='d-flex align-items-center'>
                          <div className='col-3 pl-0'>
                            <label className='m-0'>Side*:</label>
                          </div>
                          <EditingSelect
                            name='imageLaterality'
                            onChange={handleFrameChange.bind(null, index, 'caseLeftFrame')}
                            value={item.caseLeftFrame.imageLaterality || ''}
                            items={leftData.sides || []}
                            useProperty='value'
                            displayProperty='name'
                            required={true}
                            editing={true}
                          />
                        </li>
                        <li className='d-flex align-items-center'>
                          <div className='col-3 pl-0'>
                            <label className='m-0'>Angle*:</label>
                          </div>
                          <EditingSelect
                            name='viewPosition'
                            onChange={handleFrameChange.bind(null, index, 'caseLeftFrame')}
                            value={item.caseLeftFrame.viewPosition || ''}
                            items={leftData.angles || []}
                            useProperty='value'
                            displayProperty='name'
                            required={true}
                            editing={true}
                          />
                        </li>
                        <li className='d-flex align-items-center'>
                          <div className='col-3 pl-0'>
                            <label className='m-0'>Slice*:</label>
                          </div>
                          <EditingSelect
                            name='slice'
                            onChange={handleFrameChange.bind(null, index, 'caseLeftFrame')}
                            value={item.caseLeftFrame.slice || ''}
                            items={leftData.slices || []}
                            useProperty='value'
                            displayProperty='name'
                            required={true}
                            editing={true}
                          />
                        </li>
                      </div>
                      <div className='col p-0 ml-2'>
                        <p className='mb-1 mt-2 fw-600'>Frame 2*:</p>
                        <li className='d-flex align-items-center'>
                          <div className='col-3 pl-0'>
                            <label className='m-0'>Type*:</label>
                          </div>
                          <EditingSelect
                            name='imageType'
                            onChange={handleFrameChange.bind(null, index, 'caseRightFrame')}
                            value={item.caseRightFrame.imageType || ''}
                            items={rightData.types || []}
                            useProperty='value'
                            displayProperty='name'
                            required={true}
                            editing={true}
                          />
                        </li>
                        <li className='d-flex align-items-center'>
                          <div className='col-3 pl-0'>
                            <label className='m-0'>Side*:</label>
                          </div>
                          <EditingSelect
                            name='imageLaterality'
                            onChange={handleFrameChange.bind(null, index, 'caseRightFrame')}
                            value={item.caseRightFrame.imageLaterality || ''}
                            items={rightData.sides || []}
                            useProperty='value'
                            displayProperty='name'
                            required={true}
                            editing={true}
                          />
                        </li>
                        <li className='d-flex align-items-center'>
                          <div className='col-3 pl-0'>
                            <label className='m-0'>Angle*:</label>
                          </div>
                          <EditingSelect
                            name='viewPosition'
                            onChange={handleFrameChange.bind(null, index, 'caseRightFrame')}
                            value={item.caseRightFrame.viewPosition || ''}
                            items={rightData.angles || []}
                            useProperty='value'
                            displayProperty='name'
                            required={true}
                            editing={true}
                          />
                        </li>
                        <li className='d-flex align-items-center'>
                          <div className='col-3 pl-0'>
                            <label className='m-0'>Slice*:</label>
                          </div>
                          <EditingSelect
                            name='slice'
                            onChange={handleFrameChange.bind(null, index, 'caseRightFrame')}
                            value={item.caseRightFrame.slice || ''}
                            items={rightData.slices || []}
                            useProperty='value'
                            displayProperty='name'
                            required={true}
                            editing={true}
                          />
                        </li>
                      </div>
                    </div>
                  )}
                  <li className='d-flex'>
                    <div className='col-3 pl-0'>
                      <label className='m-0'>Step Comments:</label>
                    </div>
                    <EditingTextArea
                      type='text'
                      name='title'
                      value={item.title}
                      onChange={handleStepChange.bind(null, index)}
                      className='p-0 is-large'
                      placeholder='Enter Description'
                      required
                      editing={true}
                      access={true}
                    />
                  </li>
                </ul>
                <div className='mt-3 d-flex align-items-center justify-content-between'>
                  <div>
                    {form.answers.steps.length > 1 && (
                      <button
                        type='button'
                        className='btn btn-sm pl-0 text-danger d-flex align-items-center'
                        onClick={removeSteps.bind(null, index)}
                      >
                        <IconBin className={'mr-2'} />
                        Remove
                      </button>
                    )}
                  </div>
                  {isLast && (
                    <button
                      type='button'
                      className='btn btn-blue add-new-frames fz-14'
                      onClick={onAddSteps}
                    >
                      Add Substep +
                    </button>
                  )}
                </div>
              </div>
            );
          })}
      </div>
      <div className='border-top mt-4 d-flex justify-content-between pt-3'>
        <div>
          {caseData.hasBIRADS && (
            <button
              type='button'
              disabled={fetch}
              className='btn btn-sm pl-0 text-danger d-flex align-items-center'
              onClick={onRemoveBiards}
            >
              <IconBin className={'mr-2'} />
              Remove BIRADS
            </button>
          )}
        </div>
        <div>
          {!caseData.hasBIRADS && (
            <button
              type='button'
              disabled={fetch}
              onClick={onSaveWithDraft}
              className='btn btn-outline-blue fw-500 mr-3 add-new-frames fz-14'
            >
              Save as Draft
            </button>
          )}
          <button
            type='submit'
            disabled={fetch}
            className='btn px-4 btn-primary add-new-frames fz-14'
          >
            {!caseData.hasBIRADS ? 'Publish' : 'Save'}
          </button>
        </div>
      </div>
    </form>
  );
};

export default CreationForm;
