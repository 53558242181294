/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';

const QuizViewImageColumn = ({ column, columnsCount, selectedImg }) => {
  const { name, items } = column;

  return (
    <div className={`quiz_view_img_column_container ${columnsCount < 3 && 'wide'}`}>
      <div className='head_block'>{name}</div>
      <div className='body_block'>
        <div className='scrollable_block'>
          {items?.length &&
            items.map((imgItem, idx) => {
              return (
                <div
                  key={idx}
                  className={`img_item ${selectedImg?.imageId === imgItem.imageId && 'selected'}`}
                >
                  <img alt='category_img' src={imgItem.imageUrl} />
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export const ImageTypeView = ({ question }) => {
  const columnsObj = {};
  if (question.answersImageCategories) {
    question.answersImageCategories.forEach(imgCat => {
      if (!columnsObj[imgCat.category] && imgCat.category) {
        columnsObj[imgCat.category] = {
          name: imgCat.category,
          items: [{ imageId: imgCat.imageId, imageUrl: imgCat.imageUrl }],
        };
      } else if (imgCat.category) {
        columnsObj[imgCat.category] = {
          ...columnsObj[imgCat.category],
          items: [
            ...columnsObj[imgCat.category].items,
            { imageId: imgCat.imageId, imageUrl: imgCat.imageUrl },
          ],
        };
      }
    });
  }
  const imageColumns = Object.values(columnsObj);
  return (
    <>
      <div className='descriptions-block'>
        <span className='drag-descriprion'>
          Sort the items by dragging them to the appropriate boxes.{' '}
        </span>
      </div>
      <div className='images-columns-container custom_scrollbar_block'>
        {imageColumns &&
          imageColumns.map((column, colIdx) => {
            return (
              <QuizViewImageColumn
                column={column}
                key={colIdx}
                columnsCount={question.answersImageCategories.length}
              />
            );
          })}
      </div>
    </>
  );
};
