import React from 'react';
import { Link, useParams } from 'react-router-dom';
import Button from 'shared/components/Button';
import { useDialog } from 'shared/hooks/useDialog';
import { formatDateWithSlashes } from 'utils/formatHelpers';
import { hasAccess } from 'utils/permissionHelper';
import { CreateLinkDialog } from '../../Products/components/CreateLinkDialog';

const DetailsBlock = ({ className, entity }) => {
  const {
    name = '',
    imageUrl = '',
    address,
    manager,
    members = [],
    licenses = [],
    products = [],
  } = entity;
  const params = useParams();

  const createLinkDialog = useDialog();

  const handleOpenCreateLink = data => {
    createLinkDialog.openDialog(data);
  };

  const currentProducts = products.map(prod => ({
    ...prod,
    urlPath: prod.productName.toLowerCase().replace(/[\W_]+/g, '_'),
  }));

  return (
    <>
      <div className='details-container right-details-wrapper'>
        <div className={`tags-view-block right-details-block ${className || ''}`}>
          <h1 className='title'>Entity</h1>
          <div className='d-flex flex-wrap tag-wrap entity-banner-block'>
            {imageUrl ? (
              <img className='w-100' alt={name} src={imageUrl} />
            ) : (
              <div className='empty-img-block'>{name.split('')?.[0]}</div>
            )}
            <h6 className='name mb-0'>{name}</h6>
            {!!address && <p className='line-info'>{address}</p>}
          </div>
          <div className='list-block'>
            <div className='detail-item'>
              <span className='detail-label'>Manager</span>
              <span className='dotted-row' />
              <span className='detail-value'>{manager?.fullName || '-'}</span>
            </div>
            <div className='detail-item'>
              <span className='detail-label'>Members</span>
              <span className='dotted-row' />
              <span className='detail-value'>{members.length || '-'}</span>
            </div>
            <div className='detail-item'>
              <span className='detail-label'>License Keys</span>
              <span className='dotted-row' />
              <span className='detail-value'>{licenses.length || '-'}</span>
            </div>
          </div>
        </div>
        <div className='actions-block'>
          <Link
            className={`btn-blue regular-action-btn text-nowrap ${!hasAccess('products_create') &&
              'global_disabled'}`}
            to={`/entity/${params.id}/edit`}
          >
            Edit
          </Link>
          <Button
            className={`btn-blue regular-action-btn text-nowrap ${!hasAccess('products_create') &&
              'global_disabled'}`}
            onClick={handleOpenCreateLink}
          >
            Configure Link
          </Button>
          <Button className={`btn-outline-blue outlined-action-btn w-100 text-nowrap h-34 p-0`}>
            <Link className='inner-btn-link' to={`/entity/${params.id}/users`}>
              Manage Members
            </Link>
          </Button>
          <Button className={`btn-outline-blue outlined-action-btn w-100 text-nowrap h-34 p-0`}>
            <Link className='inner-btn-link' to={`/entity/${params.id}/licenses/${params.id}`}>
              Manage License Keys
            </Link>
          </Button>
        </div>
      </div>
      <CreateLinkDialog
        defaultEntity={entity}
        isOpen={createLinkDialog.isOpen}
        onClose={() => createLinkDialog.closeDialog()}
        products={currentProducts}
      />
    </>
  );
};

export default DetailsBlock;
