/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useSelector } from 'react-redux';
import ClickArea from 'shared/components/ClickArea';
import Loading from 'shared/components/Loading';
import ListActionButtons from 'shared/components/ListActionButtons';
import { formatDateWithDots } from 'utils/appHelpers';
import { courseModes } from 'app/Main/configs';
import { hasAccess } from 'utils/permissionHelper';
import { hideCourseManageActions } from 'utils/permissions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, makeStyles } from '@material-ui/core';
import { ProductPagesList } from './ProductPagesList';

const useStyles = makeStyles({
  listItem: {
    display: 'block',
    margin: '0',
    padding: '0',
  },
  summary: {
    padding: '0',
  },
  expandIcon: {
    position: 'absolute',
    right: -4,
  },
});

const headers = [
  {
    name: 'Product Name',
    className: 'col-3',
    key: 'name',
  },
  {
    name: 'Registered',
    className: 'col',
    key: 'registeredUsers',
  },
  {
    name: 'Completed',
    className: 'col',
    key: 'completedUsers',
  },
  {
    name: 'Entities',
    className: 'col',
    key: 'entities',
  },
  {
    name: 'Updated At',
    className: 'col',
    key: 'updatedAt',
  },
  {
    name: 'Actions',
    className: 'p-2 d-flex action-buttons-container min_132 col-2',
    key: '',
  },
];

const ProductsList = ({
  list,
  order,
  changeOrder,
  fetch,
  onItemRemoveConfirm,
  onItemRemoveDisabled,
  handleCreateLinkClick,
}) => {
  const classes = useStyles();
  const account = useSelector(state => state.account);
  if (fetch) return <Loading className='mt-5' />;
  if (!list || !list.length)
    return <div className='text-black-50 text-center mt-4'>No Results Found</div>;

  const actions = [
    {
      type: 'button',
      icon: 'more',
      title: 'Copy Link',
      disable: () => !hasAccess('products_create'),
      onClick: handleCreateLinkClick,
      hide: hideCourseManageActions.bind(null, account),
    },
    { type: 'link', icon: 'view', title: 'View', to: item => `/product/${item.id}/view` },
    {
      type: 'link',
      icon: 'copyAndCreate',
      title: 'Copy & Create',
      disable: () => !hasAccess('products_create'),
      to: () => `/product/new`,
      hide: hideCourseManageActions.bind(null, account),
    },
    {
      type: 'link',
      icon: 'edit',
      title: 'Edit',
      disable: item =>
        (item.isMarketingMaterial && !hasAccess('marketing_course_create')) ||
        (!item.isMarketingMaterial && !hasAccess('education_course_create')),
      to: item => `/product/${item.id}/edit`,
      hide: hideCourseManageActions.bind(null, account),
    },
    {
      type: 'button',
      icon: 'delete',
      title: 'Remove',
      disabled: onItemRemoveDisabled,
      onClick: item => onItemRemoveConfirm(item),
      hide: hideCourseManageActions.bind(null, account),
    },
  ];

  return (
    <div className='users-lists'>
      <ul className='p-0'>
        <li className='d-flex list-header align-items-center'>
          {headers.map((item, index) => (
            <ClickArea
              key={item.name + index}
              className={`d-flex text-body justify-content-between align-items-center ${
                item.className
              }`}
              onClick={changeOrder.bind(null, item)}
            >
              {item.name}
              {item.key && order.orderKey === item.key && (
                <button
                  className={`btn ml-2 p-0 dropdown-toggle ${order.order ? '' : 'rotate-180'}`}
                />
              )}
            </ClickArea>
          ))}
        </li>
        {list &&
          list.map((item, idx) => {
            return (
              <Accordion key={item.id} square>
                <AccordionSummary
                  aria-controls={`panel${idx}a-content`}
                  id={`panel${idx}a-header`}
                  className={classes.summary}
                  classes={{ content: classes.listItem }}
                >
                  <li key={item.id} className='d-flex list-item h-not-set'>
                    <div className='d-flex col-3 align-items-center'>
                      <img
                        src={item.images?.[0]?.imageUrl}
                        alt={item.name}
                        className='user-image no-radius mr-2'
                      />
                      <div>
                        <b>{item.name}</b>
                      </div>
                    </div>
                    <div className='col align-items-center d-flex'>
                      {item?.registeredUsers || '0'} users
                    </div>
                    <div className='col align-items-center d-flex'>
                      {item?.completedUsers || '0'} users
                    </div>
                    <div className='col align-items-center d-flex'>
                      {item?.entities?.length ? `${item.entities.length}` : '-'}
                    </div>
                    <div className='col align-items-center d-flex'>
                      {formatDateWithDots(item.createdAt || item.updatedAt) || '-'}
                    </div>
                    <div className='p-2 d-flex action-buttons-container align-items-center min_132 col-2'>
                      <ListActionButtons actions={actions} item={item} />
                      <ExpandMoreIcon className={classes.expandIcon} />
                    </div>
                  </li>
                </AccordionSummary>
                <AccordionDetails>
                  <ProductPagesList list={item?.pages} />
                </AccordionDetails>
              </Accordion>
            );
          })}
      </ul>
    </div>
  );
};

export default ProductsList;
