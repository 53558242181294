import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import ClickArea from 'shared/components/ClickArea';
import Loading from 'shared/components/Loading';
import keyIcon from 'assets/keyIcon.svg';
import resendIcon from 'assets/reinvite.svg';
import { getError, getImageUrl } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import { useSelector } from 'react-redux';
import { hasAccess } from 'utils/permissionHelper';
import { IconBin } from 'shared/components/Icons';

const headers = [
  {
    name: 'Name Surname',
    className: 'col-3 d-flex justify-content-between align-items-center',
    key: 'firstName',
  },
  {
    name: 'Email',
    className: 'col-3',
    key: 'email',
  },
  {
    name: 'Invitation State',
    className: 'col',
    key: 'status',
  },
  {
    name: 'User State',
    className: 'col',
    key: 'status',
  },
  {
    name: 'License keys',
    className: 'col-2',
    key: 'licenseKeys',
  },
  {
    name: 'Actions',
    className: 'col  mw-138',
    key: 'actions',
  },
];

const userStates = {
  1: 'Active',
  2: 'Inactive',
  3: 'Pending',
};

const Lists = ({
  list,
  order,
  changeOrder,
  fetch,
  onItemRemoveConfirm,
  onItemRemoveDisabled,
  onItemKeysClick,
  getData,
  entityId,
}) => {
  const account = useSelector(state => state.account);
  const { enqueueSnackbar } = useSnackbar();
  const [userInviteFetch, setUserInviteFetch] = useState(false);
  if (fetch) return <Loading className='mt-5' />;

  if (!list || !list.length)
    return <div className='text-black-50 text-center mt-4'>No Results Found</div>;

  const handleOrderChange = item => {
    if (!item.key) return;
    changeOrder({
      orderKey: item.key,
      order: !order.order,
    });
  };

  const onItemResendClick = async user => {
    try {
      setUserInviteFetch(true);
      const id = user.managerId || user.memberId;
      const { data } = await Api.post(`/admin/resendinvitation/${id}`, {
        entityId: entityId,
      });
      getData();
      enqueueSnackbar(data.message, { variant: 'success' });
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setUserInviteFetch(false);
    }
  };

  return (
    <div className='users-lists'>
      <ul className='p-0'>
        <li className='d-flex list-header align-items-center'>
          {headers.map((item, index) => (
            <ClickArea
              key={item.name + index}
              className={`text-body d-flex align-items-center ${item.className}`}
              onClick={handleOrderChange.bind(null, item)}
            >
              {item.name}
              {item.key && order.orderKey === item.key && (
                <button
                  className={`btn ml-2 p-0 dropdown-toggle ${order.order ? '' : 'rotate-180'}`}
                />
              )}
            </ClickArea>
          ))}
        </li>
        {list &&
          list.map((item, index) => {
            const isManagerUser = account.id === item.userId;
            const isDeactivated = item.userStatus === 2;
            const emailPendding = item.invitationStatus === 3;
            return (
              <li key={index} className='d-flex list-item h-not-set'>
                <div className='d-flex col-3 align-items-center'>
                  <img
                    src={getImageUrl(item.imageUrl)}
                    alt='img'
                    className='user-image no-radius mr-2'
                  />
                  <div>
                    <b>
                      {item.name || '-'} {item.surname || '-'}
                    </b>
                    <p className='m-0 text-black-50'>{item.isManager ? 'Manager' : 'Member'}</p>
                  </div>
                </div>
                <div className='col-3 d-flex align-items-center'>{item.email || '-'}</div>
                <div className='col d-flex align-items-center'>
                  {emailPendding ? 'Invited' : 'Accepted'}
                </div>
                <div className='col d-flex align-items-center'>
                  {userStates[item.userStatus] || '-'}
                </div>
                <div className='col-2 d-flex align-items-center justify-content-start'>
                  <span>
                    {item.licenseKeys
                      ? item.licenseKeys.map((item, i) => <div key={i}>{item.key}</div>)
                      : '-'}
                  </span>
                </div>
                <div className='col d-flex align-items-center justify-content-start mw-138'>
                  <div className='d-flex align-items-center p-2 action-buttons'>
                    {emailPendding && hasAccess('entity_user_create') && (
                      <button
                        disabled={userInviteFetch}
                        type='button'
                        className='btn btn-sm bg-white mr-2 p-0 action-item'
                        onClick={onItemResendClick.bind(null, item)}
                      >
                        <img width='30' src={resendIcon} alt='icon' />
                      </button>
                    )}
                    {hasAccess('entity_user_create') && (
                      <button
                        type='button'
                        className='btn btn-sm bg-white mr-2 p-0 action-item'
                        disabled={item.isManager || !item.userId}
                        onClick={onItemKeysClick.bind(null, item)}
                      >
                        <img width='30' src={keyIcon} alt='icon' />
                      </button>
                    )}
                    {hasAccess('entity_user_create') && (
                      <button
                        disabled={onItemRemoveDisabled || isManagerUser || isDeactivated}
                        onClick={onItemRemoveConfirm.bind(null, item)}
                        type='button'
                        className='btn action-item remove'
                      >
                        <IconBin />
                      </button>
                    )}
                  </div>
                </div>
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default Lists;
