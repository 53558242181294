import React from 'react';
import { lessonTypes, lessonStudyTechTypes } from 'app/Main/configs';
import { useSnackbar } from 'notistack';
import attachIcon from 'assets/attach.svg';
import EditingInput from 'shared/components/editingForms/EditingInput';
import EditingSelect from 'shared/components/editingForms/EditingSelect';
import EditingTextArea from 'shared/components/editingForms/EditingTextArea';
import { getError } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import TagsSelect from 'shared/components/TagsSelect';
import { courseTags } from 'configs';
import EditingCheckBox from 'shared/components/editingForms/EditingCheckBox';
import { hasAccess } from 'utils/permissionHelper';

const LessonGeneralStep = ({ form, setForm, data, setData, isInUse, isRequired }) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = ({ target }) => {
    const { name, value, checked, type } = target;
    const val = type === 'checkbox' ? (checked ? 1 : 0) : value;
    setForm({ ...form, [name]: val });
    if (name === 'contentType' && data.contentFile) {
      setData({ ...data, contentFile: null });
    }
  };

  const onImageUpload = async e => {
    try {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append('file', file);
      formData.append('isdraft', 'true');
      const res = await Api.post('/upload', formData);
      setData({ ...data, lessonImage: res.data.data });
      setForm({ ...form, uploadImageId: res.data.data.id });
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const lessonImg = data.lessonImage && data.lessonImage.url;

  return (
    <div className='px-2'>
      <h6 className='color-blue mb-3'>Lesson General Settings</h6>
      <div className='col-7 p-0 user-creation-form'>
        <ul className='p-0 mb-0'>
          <li className='d-flex align-items-center'>
            <div className='col-3 pl-0'>
              <label className='m-0'>Lesson Name*:</label>
            </div>
            <EditingInput
              type='text'
              name='name'
              value={form.name}
              onChange={handleChange}
              className='p-0'
              placeholder='Enter Name'
              required={isRequired}
              editing={true}
              maxLength='180'
            />
          </li>
          <li className='d-flex align-items-center'>
            <div className='col-3 pl-0'>
              <label className='m-0'>Lesson Image:</label>
            </div>
            <div className='upload-file-form w-100 overflow-hidden'>
              <input
                id='file'
                name='file'
                type='file'
                className='flex-fill hide'
                onChange={onImageUpload}
              />
              <label
                htmlFor='file'
                className={`d-flex justify-content-between align-items-center text-nowrap ${
                  lessonImg ? 'bg-transparent pl-0' : ''
                }`}
              >
                {lessonImg && <img height='40' className='mr-2' src={lessonImg} alt='img' />}
                {!lessonImg && 'Upload Image'}
                <div>
                  {lessonImg && 'Change Image'}
                  <img src={attachIcon} className='ml-2' alt='icon' />
                </div>
              </label>
            </div>
          </li>
          <li className='d-flex align-items-center'>
            <div className='col-3 pl-0'>
              <label className='m-0'>Lesson Type*:</label>
            </div>
            <EditingSelect
              name='contentType'
              onChange={handleChange}
              value={form.contentType}
              items={lessonTypes}
              useProperty='id'
              displayProperty='name'
              required={isRequired}
              editing={!isInUse}
              hideDefault={true}
              valueClassName='pl-0'
            />
          </li>
          <li className='d-flex'>
            <div className='col-3 pl-0 pt-1'>
              <label className='m-0'>Short Description*:</label>
            </div>
            <EditingTextArea
              type='text'
              name='description'
              value={form.description}
              onChange={handleChange}
              className='p-0 is-large'
              placeholder='Enter Description'
              required={isRequired}
              editing={true}
              access={true}
              maxLength='2000'
            />
          </li>
          {Number(form.contentType) === 3 && (
            <li className='d-flex align-items-center'>
              <div className='col-3 pl-0'>
                <label className='m-0'>Study tech*:</label>
              </div>
              <EditingSelect
                name='studyTech'
                onChange={handleChange}
                value={form.studyTech}
                items={lessonStudyTechTypes}
                useProperty='id'
                displayProperty='name'
                required={isRequired}
                editing={!isInUse}
                valueClassName='pl-0'
              />
            </li>
          )}
          <li className='d-flex align-items-center'>
            <div className='col-3 pl-0'>
              <label className='m-0'>Lesson Nickname:</label>
            </div>
            <EditingInput
              type='text'
              name='nickName'
              value={form.nickName}
              onChange={handleChange}
              className='p-0'
              placeholder='Enter Nickname'
              editing={true}
              access={true}
              maxLength='255'
            />
          </li>
          <li className='d-flex align-items-center'>
            <div className='col-3 pl-0'>
              <label className='m-0'>Admin Tags:</label>
            </div>
            <TagsSelect
              placeholder='Add a New Tag'
              value={data.tags}
              onChange={tags => setData({ ...data, tags })}
              type={courseTags.admin}
            />
          </li>
          {hasAccess('marketing_lessons_create') && (
            <li className='d-flex align-items-center'>
              <div className='col-3 pl-0'>
                <label className='m-0'>Marketing Material:</label>
              </div>
              <EditingCheckBox
                name='isMarketingMaterial'
                value={form.isMarketingMaterial}
                onChange={handleChange}
                className='p-0'
                checked={form.isMarketingMaterial}
                editing={true}
                disabled={!hasAccess('education_lessons_create')}
              />
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default LessonGeneralStep;
